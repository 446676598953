import {
    DOWNLOAD_TRANSACTIONS_ERROR,
    DOWNLOAD_TRANSACTIONS_SUCCESS,
    EDIT_TRANSACTIONS_SUCCESS,
    GET_LINK_TOKEN_ERROR,
    GET_LINK_TOKEN_SUCCESS,
    LOAD_BUDGET_PLAN_MONTH_SUCCESS,
    LOAD_BUDGET_PLAN_REVIEW_ERROR,
    LOAD_BUDGET_PLAN_REVIEW_SUCCESS,
    SAVE_BUDGET_PLAN_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    linkToken: null,
    transactions: null,
    error: null,
    success: null,
};

const plaidReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LINK_TOKEN_ERROR:
            return Object.assign({}, state, {
                linkToken: null,
                error: action.payload,
            });
        case GET_LINK_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                linkToken: action.payload.token,
                error: null,
            });
        case DOWNLOAD_TRANSACTIONS_ERROR:
            return Object.assign({}, state, {
                transactions: null,
                error: action.payload,
                success: null,
            });
        case DOWNLOAD_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                transactions: action.payload.transactions,
                error: null,
                success: action.payload,
            });
        case EDIT_TRANSACTIONS_SUCCESS:
        case LOAD_BUDGET_PLAN_REVIEW_ERROR:
        case LOAD_BUDGET_PLAN_REVIEW_SUCCESS:
        case SAVE_BUDGET_PLAN_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                success: null,
            });
        case LOAD_BUDGET_PLAN_MONTH_SUCCESS:
            return Object.assign({}, state, {
                transactions: null,
                success: null,
                error: null,
            });
        default:
            return state;
    }
};

export default plaidReducer;
