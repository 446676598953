/**
 * Account Service
 */

import http from '../utils/http';

class accountService {
    static async saveSignup(signup) {
        return http.post(`/api/account`, signup).then(res => res.body);
    }

    static async doLogin(login) {
        return http.post(`/api/login`, login).then(res => res.body);
    }

    static async editAccount(account) {
        return http.put(`/api/account`, account).then(res => res.body);
    }

    static async editPassword(details) {
        return http.put(`/api/editPassword`, details).then(res => res.body);
    }

    static async loadAccount() {
        return http.get(`/api/account`).then(res => res.body);
    }

    static async deleteAccount(user, deletionDate) {
        return http.delete(`/api/account`, {user: user, deletionDate: deletionDate}).then(res => res.body);
    }

    static async reactivateAccount(user) {
        return http.post(`/api/account/reactivate`, {user: user}).then(res => res.body);
    }

    static async forgotPasswordLookup(email) {
        return http.post(`/api/forgotPassword`, {email: email}).then(res => res.body);
    }

    static async verifyResetCode(resetCode) {
        return http.post(`/api/verifyResetCode`, {resetCode: resetCode}).then(res => res.body);
    }

    static async incrementUserLogins(user) {
        return http.put(`/api/login`, user).then(res => res.body);
    }

    static async generateSecretAndQR() {
        return http.get(`/api/2fa`).then(res => res.body);
    }

    static async verifyAuthCode(userId, code) {
        return http.post(`/api/2fa`, {userId: userId, code: code}).then(res => res.body);
    }

    static async disable2fa(user) {
        return http.delete(`/api/2fa`, {user: user}).then(res => res.body);
    }

    static async impersonateUser(user, id, password) {
        return http.post(`/api/impersonate`, {user: user, id: id, password: password}).then(res => res.body);
    }

    static async updateUserPreferences(user, startDate, endDate, breakdown) {
        return http.post(`/api/userPreferences`, {user, startDate, endDate, breakdown}).then(res => res.body);
    }

    static async verifyAccount(code) {
        return http.post(`/api/verifyAccount`, {code}).then(res => res.body);
    }
}

export default accountService;
