import moment from 'moment';
import { calculateAverage } from 'utils/num';
import IncomeIcon from 'images/icons/income.svg';
import SavingsIcon from 'images/icons/savings.svg';
import SpendingIcon from 'images/icons/spending.svg';

const IRREGULAR = "1.0";

export const calculateRowTotal = (row, actual) => {
    if (actual) {
        return (row.actual) ? Number(row.actual) : 0;
    } else if (row.frequency === IRREGULAR) {
        return calculateAverage(row.low, row.high);
    } else if (row.amount) {
        return Number(row.amount) * Number(row.frequency);
    } else {
        return calculateAverage(row.low, row.high);
    }
};

export const calculateTotal = (records, actual = false) => {
    let total = 0;

    records.forEach(t => {
        total += calculateRowTotal(t, actual);
    });

    return total;
};

export const calculateTotalPlanMonths = (start, end) => {
    return end.diff(start, 'months') + 1;
};

export const calculateReviewPacing = (start, end, totalPlanMonths, reviewTotals, state) => {
    let startActual = start, endActual = end;

    // Have to take into account plans spanning 2 years //
    // if (moment(startActual).dayOfYear() > moment(endActual).dayOfYear()) {
    //     startActual = moment(startActual).subtract(1, 'years');
    //     endActual = moment(endActual).subtract(1, 'years');
    // }

    const totalPlanDays = Math.ceil(endActual.diff(startActual, 'days', true));

    let startToToday = Math.ceil(moment().endOf('day').diff(startActual, 'days', true));
    startToToday = (startToToday < 0) ? 365 + startToToday : startToToday;
    const pace = startToToday / totalPlanDays;

    let remainingPlanMonths = endActual.diff(moment().startOf('month').subtract(1, 'days'), 'months');
    if (remainingPlanMonths > totalPlanMonths) {
        remainingPlanMonths = totalPlanMonths;
    }
    remainingPlanMonths = Math.max(remainingPlanMonths, 1);

    const elapsedMonthsSpending = Math.round(moment().endOf('month').diff(startActual, 'months', true));
    const elapsedMonthsIncome = elapsedMonthsSpending -1;

    let reviewPacing = [
        { id: 'income', name: 'Income', icon: IncomeIcon, alt: 'Income icon, teal, hand holding dollar bills', verb: 'earn' },
        { id: 'savings', name: 'Savings', icon: SavingsIcon, alt: 'Savings icon, purple, piggy bank with coin peeking out the top', verb: 'save' },
        { id: 'spending', name: 'Spending', icon: SpendingIcon, alt: 'Spending icon, orange, dollar bills in envelope', verb: 'spend' },
    ];

    reviewPacing.forEach(t => {
        t.records = state[t.id];
        t.review = state[t.id+'Review'];

        if (state[t.id+'Review'] !== 'all') {
            t.selectedItem = state[t.id].filter(rec => rec.id === Number(state[t.id+'Review']))[0];

            const selectedTotal = reviewTotals[t.id].filter(rec => rec.id === Number(state[t.id+'Review']));
            t.selectedItem.total = (selectedTotal.length === 0) ? 0 : selectedTotal[0].total;
            if (Number.isNaN(t.selectedItem.total)) {
                t.selectedItem.total = 0;
            }

            if (t.selectedItem.amount) {
                t.denominator = Number(t.selectedItem.amount);
            } else if (t.selectedItem.low && !t.selectedItem.high) {
                t.denominator = Number(t.selectedItem.low);
            } else if (!t.selectedItem.low && t.selectedItem.high) {
                t.denominator = Number(t.selectedItem.high);
            } else {
                t.denominator = (Number(t.selectedItem.low) + Number(t.selectedItem.high)) / 2;
            }
        }

        t.total = (state[t.id+'Review'] === 'all') ? reviewTotals[t.id+'Total'] : t.selectedItem.total;

        t.barGoal = state[t.id+'Total'] * totalPlanMonths;
        if (state[t.id+'Review'] !== 'all') {
            t.barGoal = t.denominator * totalPlanMonths;
            if (t.selectedItem.frequency) {
                t.barGoal *= Number(t.selectedItem.frequency);
            }
        }

        t.onTrack = (t.id === 'spending') ? t.total <= (state[t.id+'Total'] * elapsedMonthsSpending) : t.total > 0 && t.total >= (state[t.id+'Total'] * elapsedMonthsIncome);
        if (state[t.id+'Review'] !== 'all') {
            t.onTrack = (t.id === 'spending') ? t.total <= (t.denominator * elapsedMonthsSpending) : t.total > 0 && t.total >= (t.denominator * elapsedMonthsIncome);
        }

        t.pace = (t.total / t.barGoal) * 100;
        if (state[t.id+'Review'] !== 'all') {
            t.pace = (t.total / (t.denominator * totalPlanMonths)) * 100;
            if (t.selectedItem.frequency) {
                t.pace = (t.total / (t.denominator * Number(t.selectedItem.frequency) * totalPlanMonths)) * 100;
            }
        }
        if (Number.isNaN(t.pace) || t.pace <= 0) {
            t.pace = 0;
        }

        t.nowNeedAmount = Math.max((t.barGoal - t.total) / remainingPlanMonths, 0);
    });

    return {
        reviewPacing,
        pace,
    };
};
