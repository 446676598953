/**
 * Data Service
 */

import http from '../utils/http';

class dataService {
    static async loadAssetsLiabilities(user) {
        return http.get(`/api/assetsLiabilities?userId=${user.id}`).then(res => res.body);
    }

    static async addAccount(user, account) {
        return http.post(`/api/assetsLiabilities`, {user: user, account: account}).then(res => res.body);
    }

    static async editAccount(user, account) {
        return http.put(`/api/assetsLiabilities`, {user: user, account: account}).then(res => res.body);
    }

    static async deleteAccount(user, account) {
        return http.delete(`/api/assetsLiabilities?userId=${user.id}&accountId=${account.id}`).then(res => res.body);
    }

    static async saveHistoricalRecord(user, today, accounts) {
        return http.post(`/api/historical`, {user: user, date: today, accounts: accounts}).then(res => res.body);
    }

    static async loadHistoricalData(userId, startDate, endDate, updatePrefs, breakdown) {
        return http.get(`/api/historical?userId=${userId}&startDate=${startDate}&endDate=${endDate}&updatePrefs=${updatePrefs}&breakdown=${breakdown}`).then(res => res.body);
    }

    static async loadHistoricalDay(user, date) {
        return http.get(`/api/historical/day?userId=${user.id}&date=${date}`).then(res => res.body);
    }

    static async saveHistoricalDay(user, accounts, date) {
        return http.post(`/api/historical/day`, {user: user, accounts: accounts, date: date}).then(res => res.body);
    }

    static async loadCashflowGoals(user) {
        return http.get(`/api/cashflowGoals?userId=${user.id}`).then(res => res.body);
    }

    static async saveCashflowGoals(user, plan) {
        return http.post(`/api/cashflowGoals`, {user: user, plan: plan}).then(res => res.body);
    }

    static async deleteCashflowGoals(user, id, type) {
        return http.delete(`/api/cashflowGoals?userId=${user.id}&id=${id}&type=${type}`).then(res => res.body);
    }

    static async loadCashflowActual(user, month, planId) {
        return http.get(`/api/cashflowActual?userId=${user.id}&month=${month}&planId=${planId}`).then(res => res.body);
    }

    static async loadCashflowReview(user, planId) {
        return http.get(`/api/cashflowReview?userId=${user.id}&planId=${planId}`).then(res => res.body);
    }

    static async loadFinancialPriorities(user) {
        return http.get(`/api/financialPriorities?planId=1&userId=${user.id}`).then(res => res.body);
    }

    static async toggleTask(task, user) {
        return http.put(`/api/task`, {task: task, user: user}).then(res => res.body);
    }

    static async addTopic(topic, user) {
        return http.put(`/api/financialPrioritiesTopic`, {topic: topic, user: user}).then(res => res.body);
    }

    static async loadTask(user, taskId) {
        return http.get(`/api/task?userId=${user.id}&taskId=${taskId}`).then(res => res.body);
    }

    static async loadTaskFromLink(url, user) {
        return http.get(`/api/taskFromLink?userId=${user.id}&url=${url}`).then(res => res.body);
    }

    static async saveSurvey(user, survey) {
        return http.post(`/api/financialPriorities`, {user: user, survey: survey}).then(res => res.body);
    }

    static async deleteCashflowPlan(userId, planId) {
        return http.delete(`/api/cashflowPlan?userId=${userId}&planId=${planId}`).then(res => res.body);
    }

    static async loadRetirementPlanner(user) {
        return http.get(`/api/retirementPlanner?userId=${user.id}`).then(res => res.body);
    }

    static async saveRetirementPlanner(user, fields) {
        return http.post(`/api/retirementPlanner`, {user: user, fields: fields}).then(res => res.body);
    }

    static async loadRPIncome(user, rpId) {
        return http.get(`/api/retirementPlannerIncome?userId=${user.id}&rpId=${rpId}`).then(res => res.body);
    }

    static async saveRPIncome(user, income, rpId) {
        return http.post(`/api/retirementPlannerIncome`, {user: user, income: income, rpId: rpId}).then(res => res.body);
    }

    static async removeRPIncome(user, income) {
        return http.delete(`/api/retirementPlannerIncome?userId=${user.id}&incomeId=${income.id}`).then(res => res.body);
    }

    static async loadRPGoals(user, rpId, year, accountIds) {
        return http.get(`/api/retirementPlannerGoals?userId=${user.id}&rpId=${rpId}&year=${year}&accountIds=${accountIds}`).then(res => res.body);
    }

    static async saveRPGoals(user, goals, rpId) {
        return http.post(`/api/retirementPlannerGoals`, {user: user, goals: goals, rpId: rpId}).then(res => res.body);
    }

    static async loadRPGoalYears(user, rpId) {
        return http.get(`/api/retirementPlannerGoalYears?userId=${user.id}&rpId=${rpId}`).then(res => res.body);
    }

    static async loadRPGoalsAll(user, rpId, accountIds) {
        return http.get(`/api/retirementPlannerGoalsAll?userId=${user.id}&rpId=${rpId}&accountIds=${accountIds}`).then(res => res.body);
    }

    static async deleteHP(user, hpId) {
        return http.delete(`/api/retirementPlannerGoals?userId=${user.id}&hpId=${hpId}`).then(res => res.body);
    }
}

export default dataService;
