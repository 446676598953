import { useEffect, useState } from 'react';
import numeral from 'numeral';
import { NUM_FORMAT } from 'constants/config';

const easeOutQuad = t => t * ( 2 - t );
const frameDuration = 1000 / 60;

const findNum = (children) => {
    const maybeNum = numeral(children).value();

    if (maybeNum == null || Number.isNaN(maybeNum)) {
        return findNum(children.props.children);
    } else {
        return maybeNum;
    }
};

const CountUpAnimation = ( { children, duration = 1500 } ) => {
	const countTo = findNum(children);
	const [ count, setCount ] = useState( 0 );

	useEffect( () => {
		let frame = 0;
		const totalFrames = Math.round( duration / frameDuration );
		const counter = setInterval( () => {
			frame++;
			const progress = easeOutQuad( frame / totalFrames );
			setCount( countTo * progress );

			if ( frame === totalFrames ) {
				clearInterval( counter );
			}
		}, frameDuration );
	}, [children, countTo, duration]);

	return numeral(count).format(NUM_FORMAT);
};

export default CountUpAnimation;
