import React, { Component } from 'react';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import './MarkComplete.scss';

class MarkComplete extends Component {
    render() {
        const { completed, onClick } = this.props;

        return (
            <div className={cn('mark-complete', completed && 'completed')} onClick={onClick}>
                <Checkbox checked={completed} onClick={onClick} />
                <label onClick={onClick}>{(completed) ? 'Task Completed' : 'Mark Complete' }</label>
            </div>
        );
    }
}

export default MarkComplete;
