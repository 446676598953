import {
    CHANGE_PLAN_TYPE_ERROR,
    CHANGE_PLAN_TYPE_SUCCESS,
    DISCOUNT_CODE_ERROR,
    DISCOUNT_CODE_SUCCESS,
    PAYMENT_SUBMIT_ERROR,
    PAYMENT_SUBMIT_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    error: null,
    success: null,
    discount: null,
};

const chargebeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISCOUNT_CODE_ERROR:
        case PAYMENT_SUBMIT_ERROR:
        case CHANGE_PLAN_TYPE_ERROR:
            return Object.assign({}, state, {
                error: action.payload,
                success: null,
            });
        case DISCOUNT_CODE_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                success: action.payload,
                discount: action.payload.discount,
            });
        case PAYMENT_SUBMIT_SUCCESS:
        case CHANGE_PLAN_TYPE_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                success: action.payload,
            });
        default:
            return state;
    }
};

export default chargebeeReducer;
