import React, { Component } from 'react';

class PlusIcon extends Component {
    render() {
        const { stroke, onClick } = this.props;

        return (
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="plus-icon" onClick={onClick}>
                <circle cx="13.043" cy="12.9497" r="11.75" stroke={stroke} strokeWidth="1.5" />
                <path d="M7.04297 12.9497L19.043 12.9497" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.043 6.94971L13.043 18.9497" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

PlusIcon.defaultProps = {
    stroke: '#CFCFCF',
    onClick: () => { return null; }
};

export default PlusIcon;
