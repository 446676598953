import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { ALERT_TIMEOUTS } from 'constants/config';
import OnTrackIcon from 'images/icons/on-track.svg';
import './StandardAlert.scss';

class StandardAlert extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        alert: PropTypes.object,
    };

    render() {
        const { onClose, alert } = this.props;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={true}
                onClose={onClose}
                autoHideDuration={ALERT_TIMEOUTS[alert.severity]}
            >
                <Alert severity={alert.severity} iconMapping={{success: <img src={OnTrackIcon} alt="On-track icon, green, checkbox" />}}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        );
    }
}

export default StandardAlert;
