export const surveyData = [
    {
        question: "Does your income meet your basic day-to-day needs (e.g. housing, food, transportation, etc.)?",
        answers: [
            { value: "yes", label: "Yes, all of the time" },
            { value: "sometimes", label: "Usually or only sometimes" },
            { value: "no", label: "Rarely or never" },
        ],
    },
    {
        question: "Do you have health insurance?",
        answers: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "not sure", label: "I'm not sure" },
        ],
    },
    {
        question: "Do you have an emergency fund?",
        answers: [
            { value: "no", label: "No, I don't have one" },
            { value: "yes", label: "Yes, but I wish it was larger" },
            { value: "funded", label: "Yes, and it's fully funded (6 months to 1 year of living expenses)" },
        ],
    },
    {
        question: "How comfortable are you with personal finance terminology? This could include terms like “Gross Income”, “Compounding Interest”, “IRA Account”, “Index Funds”, “Deductions” and more.",
        answers: [
            { value: "1", label: "1 - Not at all comfortable" },
            { value: "2", label: "2 - Below average" },
            { value: "3", label: "3 - Average" },
            { value: "4", label: "4 - Above average" },
            { value: "5", label: "5 - Extremely comfortable" },
        ],
    },
    {
        question: "How well do you know and understand your credit score?",
        answers: [
            { value: "1", label: "1 - Not at all" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5 - Very well" },
        ],
    },
    {
        question: "Which of the following types of debt do you have? Check all that apply.",
        multipleChoice: [
            { value: "none", label: "I don't have any debt" },
            { value: "credit card", label: "Credit card debt" },
            { value: "student loan", label: "Student loan" },
            { value: "auto loan", label: "Auto loan" },
            { value: "personal loan", label: "Personal loan" },
            { value: "payday loan", label: "Payday loan" },
            { value: "medical debt", label: "Medical debt" },
            { value: "mortgage", label: "Mortgage" },
            { value: "other", label: "Other" },
        ],
    },
    {
        question: "Which *best* describes where you are with your retirement savings?",
        answers: [
            { value: "none", label: "I don't have any meaningful retirement savings" },
            { value: "some", label: "I have retirement savings but my savings level and strategy could be optimized" },
            { value: "on track", label: "I'm totally on track with my retirement savings and strategy" },
        ],
    },
    {
        question: "Which of the following do you use whenever possible?",
        answers: [
            { value: "password manager", label: "Just a password manager" },
            { value: "mfa", label: "Just multi-factor authentication" },
            { value: "both", label: "Both a password manager and multi-factor authentication" },
            { value: "neither", label: "Neither or I don't know what these are" },
        ],
    },
    {
        question: "Are all your regular bills set up to be automatically paid?",
        answers: [
            { value: "full amount", label: "Yes and in full" },
            { value: "yes", label: "Yes, but not necessarily the full amount" },
            { value: "no", label: "No" },
        ],
    },
    {
        question: "Do you have any children or other dependents?",
        answers: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
        ],
    },
];
