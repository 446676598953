import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import numeral from 'numeral';
import cn from 'classnames';
import { findIndex } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import { PieChart, Pie, Cell } from 'recharts';
import Loading from 'components/Loading';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import IconButton from 'components/IconButton';
import CashflowTable from 'components/CashflowTable';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, deleteCashflowGoals, loadCashflowGoals, saveCashflowGoals, loadCashflowActual, loadCashflowReview, deleteCashflowPlan } from 'actions/dataActions';
import { calculateTotal, calculateTotalPlanMonths, calculateReviewPacing } from './utils';
import { DATE_FORMAT, NUM_FORMAT, MONTHS, DATAVIZ_COLORS } from 'constants/config';
import { CATEGORIES, EMPTY_ROWS } from 'constants/cashflowGoals';
import RequiredIcon from 'images/icons/required.svg';
import CloseIcon from 'images/icons/close.svg';
import OnTrackIcon from 'images/icons/on-track.svg';
import OffTrackIcon from 'images/icons/off-track.svg';
import IncomeIcon from 'images/icons/income.svg';
import SavingsIcon from 'images/icons/savings.svg';
import SpendingIcon from 'images/icons/spending.svg';
import DialogStyles from 'styles/DialogStyles.js';
import TooltipStyles from 'styles/TooltipStyles.js';
import './CashflowGoals.scss';

const PLAN_COLORS = ['#66D8DF', '#8878F7', '#FFA34F'];
const INCOME_DEGREES = 90;
const FREQ_ID = 'frequency';
const IRR_VALUE = '1.0';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    cashflowGoals: state.data.cashflowGoals,
    cashflowActual: state.data.cashflowActual,
    cashflowReview: state.data.cashflowReview,
    success: state.data.success,
    error: state.data.error,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadCashflowGoals: (user) => {
        dispatch(loadCashflowGoals(user));
    },
    saveCashflowGoals: (user, plan) => {
        dispatch(saveCashflowGoals(user, plan));
    },
    deleteCashflowGoals: (user, id, type) => {
        dispatch(deleteCashflowGoals(user, id, type));
    },
    loadCashflowActual: (user, month, planId) => {
        dispatch(loadCashflowActual(user, month, planId));
    },
    loadCashflowReview: (user, planId) => {
        dispatch(loadCashflowReview(user, planId));
    },
    deleteCashflowPlan: (userId, planId) => {
        dispatch(deleteCashflowPlan(userId, planId));
    },
});

class CashflowGoals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            dataLoaded: false,
            actualLoading: false,
            actualLoaded: false,
            reviewLoading: false,
            tab: 'plan',
            edit: { success: false, error: false },
            introInfoOpen: false,
            savePlanOpen: false,
            startInfoOpen: false,
            incomeInfoOpen: false,
            savingsInfoOpen: false,
            spendingInfoOpen: false,
            startMonthUpdateOpen: false,
            pacingInfoOpen: false,
            breakdownInfoOpen: false,
            resetPlanOpen: false,
            planId: null,
            startMonth: null,
            endMonth: null,
            updateMonth: '',
            income: [Object.assign({}, EMPTY_ROWS.income)],
            incomeTotal: 0,
            incomeDiff: 0,
            incomeActual: 0,
            incomeReview: 'all',
            savings: [Object.assign({}, EMPTY_ROWS.savings)],
            savingsTotal: 0,
            savingsDiff: 0,
            savingsActual: 0,
            savingsReview: 'all',
            spending: [Object.assign({}, EMPTY_ROWS.spending)],
            spendingTotal: 0,
            spendingDiff: 0,
            spendingActual: 0,
            spendingReview: 'all',
            reviewTotals: null,
            pieHover: {},
            mobile: window.innerWidth < 1440,
            reviewFilterOpen: false,
        };

        this.incomeRef = React.createRef();
        this.savingsRef = React.createRef();
        this.spendingRef = React.createRef();

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        let account = nextProps.account;
        if ((!account.loaded && account.error.error) || (account.loaded && account.user)) {
            state.loaded = true;

            if (!nextProps.financialPriorities && !nextProps.cashflowGoals && !nextProps.cashflowActual && !nextProps.cashflowReview) {
                this.props.loadFinancialPriorities(account.user);
            }
        }

        if (nextProps.cashflowGoals) {
            let plan = nextProps.cashflowGoals;
            state.dataLoaded = true;
            state.planId = plan.planId;
            state.startMonth = plan.startMonth;
            state.endMonth = plan.endMonth;
            state.income = (plan.income.length) ? plan.income : [Object.assign({}, EMPTY_ROWS.income)];
            state.incomeTotal = calculateTotal(plan.income);
            state.savings = (plan.savings.length) ? plan.savings : [Object.assign({}, EMPTY_ROWS.savings)];
            state.savingsTotal = calculateTotal(plan.savings);
            state.spending = (plan.spending.length) ? plan.spending : [Object.assign({}, EMPTY_ROWS.spending)];
            state.spendingTotal = calculateTotal(plan.spending);

            if (window.location.search.includes('tab') && plan.planId) {
                let searchTuples = {};
                window.location.search.replace('?', '').split(/&/g).forEach(pair => {
                    searchTuples[pair.split('=')[0]] = pair.split('=')[1];
                });
                this.toggleView(null, searchTuples, plan.planId);
                window.history.replaceState({}, document.title, '/cashflowGoals');
            } else if (window.location.search.includes('tab') && !plan.planId) {
                window.history.replaceState({}, document.title, '/cashflowGoals');
            }
        } else {
            if (account.loaded && account.user && !nextProps.success && !nextProps.error
                && !nextProps.cashflowActual && !nextProps.cashflowReview) {
                this.props.loadCashflowGoals(account.user);
            }
        }

        if (nextProps.cashflowActual && nextProps.cashflowActual.records) {
            let actual = nextProps.cashflowActual;
            CATEGORIES.forEach(cat => {
                this.state[cat].forEach(record => {
                    delete record.actual;
                });
            });
            actual.records.forEach(r => {
                let thisState = Object.assign({}, this.state);
                let index = findIndex(thisState[r.record_type], { id: r.record_id });
                thisState[r.record_type][index].actual = (Number.isNaN(r.amount) || r.amount === 'NaN') ? 0 : Number(r.amount);
                state[r.record_type] = thisState[r.record_type];
            });
            state.actualLoaded = true;
            state.actualLoading = false;
            state.incomeDiff = this.calculateDiff(this.state.income, true, 'income');
            state.savingsDiff = this.calculateDiff(this.state.savings, true, 'savings');
            state.spendingDiff = this.calculateDiff(this.state.spending, true, 'spending');
        }

        if (nextProps.cashflowReview && !this.state.reviewTotals) {
            let review = nextProps.cashflowReview.records;
            review.map(r => {
                r.total = Number(r.total);
                if (Number.isNaN(r.total)) r.total = 0;
                return r;
            })
            state.reviewTotals = { all: review };
            state.reviewTotals.total = review.reduce((a, c) => {
                return Number(a) + Number(c.total);
            }, 0);
            if (Number.isNaN(state.reviewTotals.total)) {
                state.reviewTotals.total = 0;
            }
            CATEGORIES.forEach(t => {
                state.reviewTotals[t] = review.filter(r => r.record_type === t);
                state.reviewTotals[t+'Total'] = state.reviewTotals[t].reduce((a, c) => {
                    return Number(a) + Number(c.total);
                }, 0);
                if (Number.isNaN(state.reviewTotals[t+'Total'])) {
                    state.reviewTotals[t+'Total'] = 0;
                }
            });
            state.reviewLoading = false;
        }

        state.edit = {
            success: nextProps.success,
            error: nextProps.error,
        };

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobile: window.innerWidth < 1440 });
    }

    updateState = (key, value, planId) => {
        let state = {};
        state[key] = value;
        this.setState(state);

        if (key === 'updateMonth') {
            this.props.loadCashflowActual(this.props.account.user, value, planId || this.state.planId);
            this.setState({ actualLoading: true, actualLoaded: false });
        }
    }

    toggleDialog = (e, id) => {
        e.preventDefault();
        let state = {};
        state[id] = !this.state[id];
        this.setState(state);
    }

    toggleView = (e, value, planId = null) => {
        if ((!this.state.planId && !planId) ||
            (this.state.planId && !this.props.cashflowGoals.startMonth &&
                !this.props.cashflowGoals.endMonth)) {
            this.toggleDialog(e, 'savePlanOpen');
        } else if (value === null) {
            return false;
        } else {
            let tab = 'plan';
            if (typeof(value) === 'string') {
                tab = value;
            } else if (typeof(value) === 'object') {
                tab = value.tab;
            }

            this.setState({ tab: tab });

            if (tab === 'update' && value.month) {
                this.updateState('updateMonth', Number(value.month), planId);
            }

            if (tab === 'review') {
                this.setState({ reviewLoading: true, reviewTotals: null });
                this.props.loadCashflowReview(this.props.account.user, planId || this.state.planId);
            }
        }
    }

    removeRow = (type, index) => {
        let row = this.state[type][index];

        // If they've saved the row, we need to confirm and make an API call //
        if (row.id) {
            let userConfirm = window.confirm('Are you sure you want to delete this record? All associated data will be deleted. This cannot be undone.');
            if (userConfirm) {
                this.props.deleteCashflowGoals(this.props.account.user, row.id, type);
            } else {
                return;
            }
        }

        this.state[type].splice(index, 1);
        let state = {};
        state[type] = this.state[type];
        let total = calculateTotal(this.state[type]);
        state[type+'Total'] = total;
        this.setState(state);
    }

    addRow = (e, type) => {
        e.stopPropagation();

        if (JSON.stringify(this.state[type][this.state[type].length - 1]) === JSON.stringify(EMPTY_ROWS[type])) {
            this.state[type].splice(this.state[type].length - 1, 1);
        }

        this.state[type].forEach(t => t.editing = false);

        this.state[type].push(Object.assign({}, EMPTY_ROWS[type]));
        let state = {};
        state[type] = this.state[type];

        this.setState(state);
    }

    editRow = (e, field, type, index) => {
        if (field === null) {
            let state = Object.assign({}, this.state);

            if (JSON.stringify(state[type][state[type].length - 1]) === JSON.stringify(EMPTY_ROWS[type])) {
                state[type].splice(state[type].length - 1, 1);
            }
            state[type].forEach(t => t.editing = false);
            state[type][index].editing = true;
            this.setState(state);

            return false;
        }

        let id = (field.value) ? field.value : field.id;
        let value = (e.value) ? e.value : e.target.value;
        let thisState = Object.assign({}, this.state);

        thisState[type][index][id] = value;
        if (id === FREQ_ID && value !== IRR_VALUE) {
            thisState[type][index].low = '';
            thisState[type][index].high = '';
        }

        let state = {};
        state[type] = thisState[type];
        let total = calculateTotal(thisState[type]);
        state[type+'Total'] = total;
        this.setState(state);

        if (thisState.tab === 'update') {
            this.calculateDiff(thisState[type], true, type);
        }
    }

    calculateDiff = (typeRecords, setState = true, type) => {
        let total = this.state[type+'Total'];
        let actual = 0;

        typeRecords.forEach(t => {
            if (t.actual) {
                total -= Number(t.actual);
                actual += Number(t.actual);
            }
        });

        if (setState) {
            let state = {};
            state[type+'Diff'] = total;
            state[type+'Actual'] = actual;
            this.setState(state);
        }

        return total;
    }

    savePlan = () => {
        const { account } = this.props;

        if (!this.state.startMonth) {
            alert('Please enter a start month');
            return;
        }

        if (!this.state.endMonth) {
            alert('Please enter an end month');
            return;
        }

        this.state.income.forEach((rec, i) => {
            if (rec.source === '' || rec.frequency === 0 ||
                (rec.frequency === '1.0' && (rec.low === '' || rec.high === '')) ||
                (rec.frequency !== '1.0' && rec.amount === '')) {
                this.state.income.splice(i, 1);
            }
        });

        this.state.savings.forEach((rec, i) => {
            if (rec.account === '' || (rec.low === '' && rec.high === '')) {
                this.state.savings.splice(i, 1);
            }
        });

        this.state.spending.forEach((rec, i) => {
            if (rec.category === '' || (rec.low === '' && rec.high === '')) {
                this.state.spending.splice(i, 1);
            }
        });

        this.setState(this.state);

        if (account.user) {
            this.props.saveCashflowGoals(account.user, this.state);
        }
    }

    resetPlan = (e) => {
        this.props.deleteCashflowPlan(this.props.account.user.id, this.state.planId);
        this.toggleDialog(e, 'resetPlanOpen');
    }

    changeReviewSource = (e, type) => {
        let state = {};
        state[type+'Review'] = e.target.value;
        this.setState(state);
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    closeSnackbar = () => {
        this.setState({ edit: {} });
    }

    barLabel = (options) => {
        const { x, y, width, height, value } = options;

        let props = {
            x: (width / 2) + (x / 2),
            y: (height / 2) + y + 9,
            fill: 'white',
        };
        if (width < 150) {
            props.x = width + 90;
            props.fill = '#585757';
        }

        return (
            <text x={props.x} y={props.y} fill={props.fill} fontSize={24} fontWeight={600}>
                {numeral(value).format(NUM_FORMAT)}
            </text>
        );
    }

    roundedRect = (props) => {
        if (props.amt === 0) {
            return null;
        }

        const radius = 35;
        const { fill, x, y, width, height } = props;

        // https://stackoverflow.com/questions/12115691/svg-d3-js-rounded-corner-on-one-corner-of-a-rectangle //
        const path = "M" + x + "," + y
             + "h" + (width - radius)
             + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
             + "v" + (height - 2 * radius)
             + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
             + "h" + (radius - width)
             + "z";

        return (<path d={path} stroke="none" fill={fill} />);
    }

    balancePieLabel = (options) => {
        const hasData = (this.state.incomeTotal > 0 || this.state.savingsTotal > 0 || this.state.spendingTotal > 0);
        const anchor = (options.x > options.cx) ? 'start': 'end';
        const rectWidth = 95, rectHeight = 21;
        const rectX = (anchor === 'start') ? options.x : options.x - rectWidth;
        const rectY = options.y - 16;

        if (!hasData) {
            return '';
        }
        return (
            <g>
                <rect x={rectX} y={rectY} width={rectWidth} height={rectHeight} fill="white"></rect>
                <text stroke="none" fill={options.fill} x={options.x} y={options.y} alignmentBaseline="middle" className="recharts-text recharts-pie-label-text" textAnchor={anchor}>
                    <tspan x={options.x} dy="0em">{numeral(options.value).format(NUM_FORMAT)}</tspan>
                </text>
            </g>
        );
    }

    renderSummaryExtra = (diff, category) => {
        if (category === 'spending') {
            if (diff >= 0) {
                return (
                    <>
                        <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                        <span>Congratulations! You beat your {category} goal by {numeral(diff).format(NUM_FORMAT)}.</span>
                    </>
                );
            } else if (diff < 0) {
                return (
                    <>
                        <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                        <span> You overspent by {numeral(Math.abs(diff)).format(NUM_FORMAT)}.</span>
                    </>
                );
            }
        } else {
            if (diff > 0) {
                return (
                    <>
                        <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                        <span>You missed your {category} goal by {numeral(diff).format(NUM_FORMAT)}.</span>
                    </>
                );
            } else if (diff === 0) {
                return (
                    <>
                        <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                        <span>Congratulations! You reached your {category} goal!</span>
                    </>
                );
            } else if (diff < 0) {
                return (
                    <>
                        <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                        <span> Congratulations! You beat your {category} goal by {numeral(Math.abs(diff)).format(NUM_FORMAT)}.</span>
                    </>
                );
            }
        }

        return null;
    };

    chartMouseover = (opts) => {
        let { fill, percent, record_type } = opts,
            total = opts.payload.total,
            label = opts?.source || opts?.account || opts?.category;

        this.setState({
            pieHover: { record_type, fill, label, percent, total },
        });
    };

    chartMouseout = () => {
        this.setState({ pieHover: {} });
    };

    render() {
        const { loaded, dataLoaded, actualLoaded, tab, edit, planId, mobile } = this.state;
        const { startMonth, endMonth, income, incomeTotal, savings, savingsTotal, spending, spendingTotal } = this.state;
        const { updateMonth, actualLoading, incomeDiff, savingsDiff, spendingDiff } = this.state;
        const { reviewLoading, reviewTotals, pieHover } = this.state;
        const { account, financialPriorities, classes } = this.props;

        if (!loaded || (loaded && account.loaded && !dataLoaded)) {
            return (<Loading />);
        } else {
            // Plan tab vars //
            const start = moment().month(startMonth-1).startOf('month').startOf('day');
            const end = (startMonth <= endMonth) ?
                moment().month(endMonth-1).endOf('month').endOf('day') :
                moment().month(endMonth-1).add(1, 'years').endOf('month').endOf('day');
            const totalPlanMonths = calculateTotalPlanMonths(start, end);
            const hasData = (incomeTotal > 0 || savingsTotal > 0 || spendingTotal > 0);
            const pieData = (hasData) ? [
                { name: 'Income', value: incomeTotal },
                { name: 'Savings', value: savingsTotal },
                { name: 'Spending', value: spendingTotal },
            ] : [
                { name: 'Income', value: 1000 },
                { name: 'Savings', value: 500 },
                { name: 'Spending', value: 500 },
            ];
            const endAngle = INCOME_DEGREES - (((pieData[1].value + pieData[2].value) / pieData[0].value) * INCOME_DEGREES);
            const balance = (hasData) ? incomeTotal - (savingsTotal + spendingTotal) : undefined;
            const pieChartMargin = (mobile) ? { top: 0, right: 0, bottom: 0, left: 0 } : { top: 80, right: 100, bottom: 0, left: 150 };

            // Update tab vars //
            let updateMonths = [], currentMonth = startMonth;
            if (startMonth && endMonth) {
                do {
                    updateMonths.push(MONTHS[currentMonth-1]);
                    if (currentMonth === 12 && endMonth !== 12) {
                        currentMonth = 1;
                    } else {
                        currentMonth += 1;
                    }
                } while (currentMonth !== endMonth+1);
            }
            let actualTotals = {};
            CATEGORIES.forEach(t => {
                actualTotals[t] = this.state[t].reduce((acc, curr) => {
                    let actual = (curr.actual) ? Number(curr.actual) : 0;
                    return acc + actual;
                }, 0);
            });
            const accomplishmentsData = [
                { id: 'income', name: 'Income', icon: IncomeIcon, alt: 'Income icon, teal, hand holding dollar bills',
                    diff: incomeDiff, total: incomeTotal, pace: Math.max(0, (actualTotals.income / incomeTotal)*100), onTrack: (incomeDiff <= 0), ref: this.incomeRef },
                { id: 'savings', name: 'Savings', icon: SavingsIcon, alt: 'Savings icon, purple, piggy bank with coin peeking out the top',
                    diff: savingsDiff, total: savingsTotal, pace: Math.max(0, (actualTotals.savings / savingsTotal)*100), onTrack: (savingsDiff <= 0), ref: this.savingsRef },
                { id: 'spending', name: 'Spending', icon: SpendingIcon, alt: 'Spending icon, orange, dollar bills in envelope',
                    diff: spendingDiff, total: spendingTotal, pace: Math.max(0, (actualTotals.spending / spendingTotal)*100), onTrack: (spendingDiff >= 0), ref: this.spendingRef },
            ];

            // Review tab vars //
            let reviewPacing, pace;
            if (tab === 'review' && reviewTotals) {
                ({ reviewPacing, pace } = calculateReviewPacing(start, end, totalPlanMonths, reviewTotals, this.state));
            }
            const planStarted = moment() >= start;

            // The contents of the snackbar on API calls //
            let alert = null;
            if (edit.success) {
                alert = { severity: 'success', msg: edit.success.msg };
            } else if (edit && edit.error) {
                alert = { severity: 'error', msg: edit.error.body.msg };
            }

            return (
                <div className="wrapper">
                    <NavBarLoggedIn account={account} fp={financialPriorities} />

                    <div className="main-bg" />

                    <div className="main cashflow-goals">
                        {tab === 'intro' &&
                            <div className="intro">
                                <p>With <strong>Cashflow Goals</strong>, you will create
                                an easy-to-follow plan for your income, saving, and
                                spending. You'll always know exactly what you need to do
                                to hit your goals and can make adjustments as needed.</p>

                                <p>You'll start with a <strong>plan</strong> for the
                                remainder of the year, <strong>update</strong> your
                                numbers monthly, and <strong>review</strong> your
                                progress whenever you like.</p>

                                <button className="plan-button" onClick={() => this.updateState('tab', 'plan')}>Create My Plan</button>
                                <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'introInfoOpen')} />
                            </div>
                        }

                        {tab !== 'intro' && <>
                            <ToggleButtonGroup value={tab} exclusive onChange={this.toggleView}>
                                <ToggleButton value="plan">Plan</ToggleButton>
                                <ToggleButton value="update">Update</ToggleButton>
                                <ToggleButton value="review">Review</ToggleButton>
                            </ToggleButtonGroup>

                            <h1>Cashflow Goals</h1>
                        </>}

                        {tab === 'plan' && <>
                            <h2 className="sub light">Create a plan by setting your goals for income, saving, and spending.</h2>

                            <div className="section-wrapper">
                                {!planId && (!startMonth || !endMonth) && <div className="cover" />}

                                <section>
                                    <h1>
                                        Plan Timeframe
                                        <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'startInfoOpen')} />
                                    </h1>
                                    <h3>What month do you want this plan to start and end?</h3>

                                    <div className="start-end-date">
                                        <div className="month-wrapper">
                                            <label>Start:</label>
                                            <select className="start-month" onChange={(e) => this.updateState('startMonth', Number(e.target.value))} value={startMonth}>
                                                <option value="">Select</option>
                                                {MONTHS.map((m, i) => <option value={m.value} key={i}>{m.label}</option>)}
                                            </select>
                                            <img src={RequiredIcon} alt="Required icon, red, star" />
                                        </div>

                                        <div className="month-wrapper">
                                            <label>End:</label>
                                            <select className="end-month" onChange={(e) => this.updateState('endMonth', Number(e.target.value))} value={endMonth}>
                                                <option value="">Select</option>
                                                {MONTHS.map((m, i) => <option value={m.value} key={i}>{m.label}</option>)}
                                            </select>
                                            <img src={RequiredIcon} alt="Required icon, red, star" />
                                        </div>

                                        {startMonth && this.props.cashflowGoals.startMonth != null && endMonth && this.props.cashflowGoals.endMonth != null && planId &&
                                            <a href="noop" className="edit" onClick={(e) => this.toggleDialog(e, 'resetPlanOpen')}>Edit</a>
                                        }
                                    </div>
                                </section>

                                <section>
                                    <h1>
                                        Planned Net Income
                                        <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'incomeInfoOpen')} />
                                    </h1>
                                    <h3>Enter your planned after-tax monthly income. For irregular income, enter the average monthly range.</h3>

                                    <div className="table-and-summary">
                                        <CashflowTable
                                            type="income"
                                            tab="plan"
                                            fields={[
                                                { id: 'source', label: 'Source', placeholder: 'Income Name' },
                                                { id: 'frequency', label: 'Frequency', options: [
                                                    { value: '4', name: 'Weekly' },
                                                    { value: '1', name: 'Once a Month' },
                                                    { value: '2', name: 'Twice a month' },
                                                    { value: '1.0', name: 'Irregular' },
                                                ] },
                                                { id: 'amount', label: 'Amount', placeholder: '$0' },
                                            ]}
                                            rows={income}
                                            removeRow={this.removeRow}
                                            addRow={this.addRow}
                                            editRow={this.editRow}
                                            mobile={mobile}
                                        />
                                        <div className="summary">
                                            <p>
                                                Your plan for average monthly net income is about:
                                                <span className="summary-amount income">{numeral(incomeTotal).format(NUM_FORMAT)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <h1>
                                        Planned Net Savings
                                        <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'savingsInfoOpen')} />
                                    </h1>
                                    <h3>Enter the planned range of after-tax income you want to allocate to savings each month.</h3>

                                    <div className="table-and-summary">
                                        <CashflowTable
                                            type="savings"
                                            tab="plan"
                                            fields={[
                                                { id: 'account', label: 'Account', placeholder: 'Account Name' },
                                                { id: 'low', label: 'Low', placeholder: '$0' },
                                                { id: 'high', label: 'High', placeholder: '$0' },
                                                { id: 'description', label: 'Description (optional)', placeholder: 'Description (optional)' },
                                           ]}
                                            rows={savings}
                                            removeRow={this.removeRow}
                                            addRow={this.addRow}
                                            editRow={this.editRow}
                                            mobile={mobile}
                                        />
                                        <div className="summary">
                                            <p>
                                                Your plan for after-tax monthly savings is about:
                                                <span className="summary-amount savings">{numeral(savingsTotal).format(NUM_FORMAT)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <h1>
                                        Planned Spending
                                        <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'spendingInfoOpen')} />
                                    </h1>
                                    <h3>Enter the planned range of monthly expenditures by account (e.g.
                                    a credit card) and/or by category for more detailed tracking.</h3>

                                    <div className="table-and-summary">
                                        <CashflowTable
                                            type="spending"
                                            tab="plan"
                                            fields={[
                                                { id: 'category', label: 'Account/Category' },
                                                { id: 'low', label: 'Low', placeholder: '$0' },
                                                { id: 'high', label: 'High', placeholder: '$0' },
                                                { id: 'description', label: 'Description (optional)', placeholder: 'Description (optional)' },
                                           ]}
                                            rows={spending}
                                            removeRow={this.removeRow}
                                            addRow={this.addRow}
                                            editRow={this.editRow}
                                            mobile={mobile}
                                        />
                                        <div className="summary">
                                            <p>
                                                Your plan for average monthly spending is about:
                                                <span className="summary-amount spending">{numeral(spendingTotal).format(NUM_FORMAT)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div className="section-wrapper">
                                {!planId && (!startMonth || !endMonth) && <div className="cover secondary" />}

                                <section className="balance-check">
                                    <h1>Plan Balance Check</h1>
                                    <h3>This shows how your monthly plan for income compares to average saving and spending. Try to ensure your total
                                    of planned savings and spending do not exceed your total income.</h3>

                                    <div className="summary">
                                        {(typeof(balance) != 'undefined' && balance >= 0) && <>
                                            <div className="summary-row">
                                                <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                                <span>Well done! With this plan on average you'll have an extra <b>{numeral(balance).format(NUM_FORMAT)}</b>.</span>
                                            </div>
                                            <div className="summary-row">
                                                <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                                <span>You should be able to meet your planned <b className="income">income</b>, <b className="savings">saving</b>, and <b className="spending">spending</b> goals.</span>
                                            </div>
                                        </>}
                                        {(typeof(balance) != 'undefined' && balance < 0) && <>
                                            <div className="summary-row">
                                                <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                                <span>Your current plan is <b>imbalanced by {numeral(-balance).format(NUM_FORMAT)}</b> a month.</span>
                                            </div>
                                            <div className="summary-row">
                                                <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                                <span>To balance your plan, you need to earn more <b className="income">income</b>, plan to <b className="savings">save</b> less, or reduce <b className="spending">spending</b>.</span>
                                            </div>
                                        </>}
                                    </div>

                                    <div className="pie-chart">
                                        <PieChart width={(mobile) ? 200 : 750} height={(mobile) ? 200 : ((balance < 0) ? 500 : 300)} margin={pieChartMargin}>
                                            <Pie
                                                dataKey="value"
                                                startAngle={180}
                                                endAngle={endAngle}
                                                data={pieData}
                                                cx={(mobile) ? '50%' : 250}
                                                cy={(mobile) ? '50%' : 235}
                                                outerRadius={(mobile) ? '80%' : 200}
                                                label={(mobile) ? null : this.balancePieLabel}
                                                labelLine={hasData && !mobile}
                                                legendType="circle"
                                            >
                                                {pieData.map((entry, i) => <Cell key={i} fill={(hasData) ? PLAN_COLORS[i] : '#E6E9ED'} />)}
                                            </Pie>
                                        </PieChart>

                                        <div className="legend">
                                            <div className="legend-row">
                                                <label className="income">Income</label>
                                                <span className="summary-amount income">{numeral(incomeTotal).format(NUM_FORMAT)}</span>
                                            </div>
                                            <div className="legend-row">
                                                <label className="savings">Savings</label>
                                                <span className="summary-amount savings">{numeral(savingsTotal).format(NUM_FORMAT)}</span>
                                            </div>
                                            <div className="legend-row">
                                                <label className="spending">Spending</label>
                                                <span className="summary-amount spending">{numeral(spendingTotal).format(NUM_FORMAT)}</span>
                                            </div>
                                        </div>

                                        {hasData &&
                                            <Tooltip
                                                title="Try not to have your spending extend below the balance line."
                                                arrow={true}
                                                placement="top-end"
                                                className={classes.Tooltip}
                                            >
                                                <div className="balance-line" style={{ top: (mobile) ? '92px' : ((balance < 0) ? '308px' : '293px')}}>
                                                    <div className="line" />
                                                    <label>Balance Line</label>
                                                </div>
                                            </Tooltip>
                                        }
                                     </div>
                                </section>
                            </div>

                            <button className="primary larger save-plan" onClick={this.savePlan}>Save Plan</button>
                        </>}

                        {tab === 'update' && <>
                            <h2 className="sub light">Update your actual monthly numbers for income, saving and spending.</h2>

                            <div className="section-wrapper">
                                <section className={cn(updateMonth === '' && 'update')}>
                                    <h1>
                                        Update Month
                                        <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'startMonthUpdateOpen')} />
                                    </h1>
                                    <h3>What month are you updating your numbers for?</h3>

                                    <div className="start-end-date update">
                                        <select className="start-month" onChange={(e) => this.updateState('updateMonth', e.target.value)} value={updateMonth}>
                                            <option value="">-- Select --</option>
                                            {updateMonths.map((m, i) => <option value={m.value} key={i}>{m.label}</option>)}
                                        </select>
                                        <img src={RequiredIcon} alt="Required icon, red, star" />
                                    </div>
                                </section>

                                {actualLoading && <Loading />}

                                {actualLoaded && <>
                                    <section>
                                        <h1>Actual Net Income</h1>
                                        <h3>
                                            Enter the actual after-tax income you made this month.
                                            You planned to earn an average of {numeral(incomeTotal).format(NUM_FORMAT)} per month.
                                        </h3>

                                        <div className="table-and-summary">
                                            <CashflowTable
                                                classes="even"
                                                type="income"
                                                tab="update"
                                                fields={[
                                                    { id: 'source', label: 'Source' },
                                                    { id: 'amount', label: 'Planned Income' },
                                                    { id: 'difference', label: 'Difference' },
                                                    { id: 'actual', label: 'Actual Income', placeholder: '$0' },
                                                ]}
                                                rows={income}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                mobile={mobile}
                                            />
                                            <div className="summary">
                                                <p>
                                                    In {MONTHS[updateMonth - 1].label}, your total net income was:
                                                    <span className="summary-amount income">{numeral(actualTotals.income).format(NUM_FORMAT)}</span>
                                                </p>

                                                <div className="summary-extra">
                                                    {this.renderSummaryExtra(incomeDiff, 'income')}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <h1>Actual Net Savings</h1>
                                        <h3>
                                            Enter the actual after-tax income you put towards savings this month.
                                            You planned to save an average of {numeral(savingsTotal).format(NUM_FORMAT)} per month.
                                        </h3>

                                        <div className="table-and-summary">
                                            <CashflowTable
                                                type="savings"
                                                tab="update"
                                                fields={[
                                                    { id: 'account', label: 'Account Name' },
                                                    { id: 'average', label: 'Planned Savings' },
                                                    { id: 'difference', label: 'Difference' },
                                                    { id: 'actual', label: 'Actual Savings', placeholder: '$0' },
                                                ]}
                                                rows={savings}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                mobile={mobile}
                                            />
                                            <div className="summary">
                                                <p>
                                                    After taxes in {MONTHS[updateMonth - 1].label}, you saved:
                                                    <span className="summary-amount savings">{numeral(actualTotals.savings).format(NUM_FORMAT)}</span>
                                                </p>

                                                <div className="summary-extra">
                                                    {this.renderSummaryExtra(savingsDiff, 'savings')}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <h1>Actual Spending</h1>
                                        <h3>
                                            Enter your actual monthly spending by account or category.
                                            You planned to spend an average of {numeral(spendingTotal).format(NUM_FORMAT)} per month.
                                        </h3>

                                        <div className="table-and-summary">
                                            <CashflowTable
                                                classes="even"
                                                type="spending"
                                                tab="update"
                                                fields={[
                                                    { id: 'category', label: 'Account/Category' },
                                                    { id: 'average', label: 'Planned Spend' },
                                                    { id: 'difference', label: 'Difference' },
                                                    { id: 'actual', label: 'Actual Spend', placeholder: '$0' },
                                                ]}
                                                rows={spending}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                mobile={mobile}
                                            />
                                            <div className="summary">
                                                <p>
                                                    In {MONTHS[updateMonth - 1].label}, you spent:
                                                    <span className="summary-amount spending">{numeral(actualTotals.spending).format(NUM_FORMAT)}</span>
                                                </p>

                                                <div className="summary-extra">
                                                    {this.renderSummaryExtra(spendingDiff, 'spending')}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </>}
                            </div>

                            {actualLoaded && <>
                                <div className="section-wrapper">
                                    <section className="accomplishments">
                                        <div className="header">
                                            <div className="desc">
                                                <h1>{MONTHS[updateMonth - 1].label} Accomplishments</h1>
                                                <h3>Check out your accomplishments for the month!</h3>
                                            </div>
                                            <div className="empty-space" />
                                        </div>

                                        {accomplishmentsData.map((a, i) => {
                                            return (
                                                <div className="category-section" key={i}>
                                                    <div className="category-metadata">
                                                        <h1>
                                                            <img src={a.icon} alt={a.alt} />
                                                            {a.name}
                                                        </h1>
                                                        <div className="summary">
                                                            {this.renderSummaryExtra(a.diff, a.id)}
                                                        </div>
                                                    </div>
                                                    <div className="pacing-bar">
                                                        <div className="bar">
                                                            <div className={cn('pace', (a.onTrack) ? 'on-track' : 'off-track', a.pace >= 99 && 'full', a.pace === 0 && 'zero')}
                                                                style={{width: Math.min(100, a.pace)+'%'}} ref={a.ref}>
                                                                <label className={cn('total', (a.onTrack) ? 'on-track' : 'off-track', a.pace >= 15 && 'inside', a.pace === 0 && 'zero')}>
                                                                    {numeral(actualTotals[a.id]).format(NUM_FORMAT)}
                                                                </label>
                                                            </div>
                                                            <label className="bar-goal">{numeral(a.total).format(NUM_FORMAT)}</label>
                                                        </div>
                                                    </div>
                                                    <div className="summary">
                                                        {this.renderSummaryExtra(a.diff, a.id)}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </section>
                                </div>

                                <button className="primary larger save-plan" onClick={this.savePlan}>Save Monthly Numbers</button>
                            </>}
                        </>}

                        {tab === 'review' && <>
                            <h2 className="sub light">Review your progress, check your pace, and see breakdowns by accounts, categories, & sources.</h2>

                            {reviewLoading && <Loading />}

                            {!reviewLoading && <>
                                <div className="section-wrapper review">
                                    <section className="plan-pacing">
                                        <div className="background">
                                            <div className="header">
                                                <div className="desc">
                                                    <h1>Plan Pacing <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'pacingInfoOpen')} /></h1>
                                                    <h2>Today: <span>{moment().format(DATE_FORMAT)}</span> <span className="pacing-legend" /></h2>
                                                    <a href="noop" className="filter-by" onClick={(e) => this.toggleDialog(e, 'reviewFilterOpen')}>Filter By ></a>
                                                </div>
                                            </div>

                                            <div className="months">
                                                {updateMonths.map((m, i, arr) => {
                                                    let classes = cn('month', planStarted && m.value-1 === moment().month() && 'active');
                                                    return (
                                                        <div className={classes} key={i} style={{width: `${(100 / arr.length)}%`}}>
                                                            {m.label.substr(0,3)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="foreground">
                                            {reviewPacing.map((rp, i) => {
                                                let label = `Net ${rp.name}`;
                                                if (rp.id === 'spending') label = 'Spending';
                                                if (mobile && rp.review !== 'all') {
                                                    const record = rp.records.filter(r => String(r.id) === rp.review)[0];
                                                    label = record?.source || record?.account || record?.category;
                                                }

                                                return (
                                                    <div className="pacing-section" key={i}>
                                                        <div className={cn('pacing-metadata', i % 2 !== 0 && 'even')}>
                                                            <div className="category-info">
                                                                <img src={rp.icon} alt={rp.alt} />
                                                                <label>{label}</label>
                                                                <select name="review" onChange={(e) => this.changeReviewSource(e, rp.id)} value={rp.review}>
                                                                    <option value="all">All {rp.name}</option>
                                                                    {rp.records.filter(r => r.id).map(r => <option value={r.id} key={r.id}>{r?.source || r?.account || r?.category}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className="summary">
                                                                {(rp.onTrack || !planStarted) && <>
                                                                    <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                                                    <span>Congrats! You're on track.</span>
                                                                </>}
                                                                {!rp.onTrack && planStarted && <>
                                                                    <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                                                    <span>To meet your goal, you now need to {rp.verb} an average of {numeral(rp.nowNeedAmount).format(NUM_FORMAT)} per month.</span>
                                                                </>}
                                                            </div>
                                                        </div>
                                                        <div className="pacing-bar review">
                                                            <div className="bar">
                                                                <div className={cn('pace', (rp.onTrack) ? 'on-track' : 'off-track', rp.pace >= 99 && 'full', rp.pace === 0 && 'zero')}
                                                                    style={{width: Math.min(100, rp.pace)+'%'}}>
                                                                    <label className={cn('total', (rp.onTrack) ? 'on-track' : 'off-track', rp.pace >= 15 && 'inside', rp.pace === 0 && 'zero')}>
                                                                        {numeral(rp.total).format(NUM_FORMAT)}
                                                                    </label>
                                                                </div>
                                                                <label className="bar-goal">{numeral(rp.barGoal).format(NUM_FORMAT)}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {planStarted &&
                                            <div className="today-container">
                                                {pace * 100 >= 0 && pace * 100 <= 100 &&
                                                    <div className="marker" style={{left: pace*100 + '%'}} />
                                                }
                                            </div>
                                        }
                                    </section>
                                </div>

                                <div className="plan-breakdown">
                                    <h1>
                                        Plan Breakdown
                                        <IconButton type="info" content="?" onClick={(e) => this.toggleDialog(e, 'breakdownInfoOpen')} />
                                    </h1>

                                    <div className="breakdowns">
                                        {reviewPacing.map((rp, i) => {
                                            const categoryName = (rp.id === 'spending') ? rp.name : 'Net ' + rp.name;

                                            let legendData = null;
                                            if ((Object.keys(pieHover).length === 0 || pieHover.record_type !== rp.id) && reviewTotals[rp.id]) {
                                                const top = reviewTotals[rp.id].sort((a, b) => {
                                                    return (a.total > b.total) ? -1 : ((a.total < b.total) ? 1 : 0);
                                                })[0];
                                                const total = reviewTotals[rp.id].reduce((acc, obj) => { return acc + obj.total; }, 0)

                                                legendData = {
                                                    record_type: rp.id,
                                                    fill: DATAVIZ_COLORS[0],
                                                    label: top?.source || top?.account || top?.category,
                                                    percent: (top) ? top.total / total : 0,
                                                    total: top?.total || 0,
                                                };
                                            } else if (Object.keys(pieHover).length > 0 && pieHover.record_type === rp.id) {
                                                legendData = pieHover;
                                            }

                                            return (
                                                <div className="breakdown-section" key={i}>
                                                    <h2>
                                                        <img src={rp.icon} alt={rp.alt} />
                                                        {categoryName}
                                                    </h2>
                                                    <p>Your total {categoryName} is {numeral(rp.total).format(NUM_FORMAT)}.</p>

                                                    <div className="pie-chart">
                                                        <PieChart width={150} height={150}>
                                                            <Pie
                                                                dataKey="total"
                                                                data={reviewTotals[rp.id]}
                                                                outerRadius={75}
                                                                label={null}
                                                                labelLine={false}
                                                                onMouseOver={this.chartMouseover}
                                                                onMouseOut={this.chartMouseout}
                                                            >
                                                                {reviewTotals[rp.id].map((e, i) => <Cell key={i} fill={DATAVIZ_COLORS[i % DATAVIZ_COLORS.length]} />)}
                                                            </Pie>
                                                        </PieChart>
                                                    </div>

                                                    <div className="breakdown-legend">
                                                        {legendData?.record_type === rp.id && legendData.percent > 0 && legendData.total > 0 &&
                                                            <div className="legend-contents">
                                                                <span className="icon" style={{ backgroundColor: legendData.fill }} />
                                                                <label style={{ color: legendData.fill }}>{legendData.label}: </label>
                                                                <span className="amounts">
                                                                    {`${Math.round(legendData.percent * 100)}%, ${numeral(legendData.total).format(NUM_FORMAT)}`}
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>}
                        </>}
                    </div>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'reviewFilterOpen')} open={this.state.reviewFilterOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'reviewFilterOpen')} />

                        <div className="review-filter">
                            <div className="review-filter-header">
                                <h1>Filter By</h1>
                            </div>

                            {reviewPacing?.map((rp, i) => {
                                return (
                                    <div className="filter-category" key={i}>
                                        <div className="filter-label">
                                            <h2>{rp.name}</h2>
                                            <ul>
                                                <li>
                                                    <input type="radio"
                                                        id={rp.id}
                                                        name={rp.id}
                                                        value="all"
                                                        checked={rp.review === 'all'}
                                                        onChange={(e) => this.changeReviewSource(e, rp.id)} />
                                                    <label htmlFor={rp.id}>{`All ${rp.name}`}</label>
                                                </li>
                                                {rp.records.filter(r => r.id).map(r => {
                                                    return (
                                                        <li key={r.id}>
                                                            <input type="radio"
                                                                id={r.id}
                                                                name={rp.id}
                                                                value={r.id}
                                                                checked={rp.review === String(r.id)}
                                                                onChange={(e) => this.changeReviewSource(e, rp.id)} />
                                                            <label htmlFor={r.id}>{r?.source || r?.account || r?.category}</label>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'introInfoOpen')} open={this.state.introInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'introInfoOpen')} />
                        <p>
                            When you're ready to save your plan, you'll be prompted to&nbsp;
                            <a href="/createAccount">create a free account</a> or <a href="/login">login</a>.
                        </p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'introInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'savePlanOpen')} open={this.state.savePlanOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'savePlanOpen')} />
                        <p>You need to save your plan before you can track or review it.</p>
                        <p>Click "Save Plan" at the bottom of the page.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'savePlanOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'startInfoOpen')} open={this.state.startInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'startInfoOpen')} />
                        <p>Your plan can be up to one year and start in any month.</p>
                        <p>We recommend creating a plan that stretches over multiple months. Don't
                        worry about being perfect each month. Instead, focus on hitting your goals
                        by the end of the plan by tracking your progress on the 'Review' tab.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'startInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'incomeInfoOpen')} open={this.state.incomeInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'incomeInfoOpen')} />
                        <p>Enter your net (after-tax) income and the frequency you
                        receive that amount of income in a month. If your income is
                        irregular, estimate a high and low number you expect to make
                        for a typical month.</p>

                        <p>For any frequency set for something other than “Once a Month”,
                        MoneySwell will calculate an average monthly income for that source.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'incomeInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'savingsInfoOpen')} open={this.state.savingsInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'savingsInfoOpen')} />
                        <p>Enter the net (after tax) savings you plan to contribute
                        to each account. This could be any account you specify as
                        long as the contributions are with after-tax dollars.</p>

                        <p>Remember, this is your <b>net</b> savings. You may be
                        saving additional money with your gross (before tax) earnings
                        (for example in a 401k or a before-tax IRA) but that should
                        not be entered here.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'savingsInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'spendingInfoOpen')} open={this.state.spendingInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'spendingInfoOpen')} />
                        <p>You can track spending by detailed categories, <b>or</b>,
                        you can track the total spending from a specific account -
                        for example a credit card or checking account.</p>

                        <p>If you want to track spending by account, click the "+"
                        icon to create a new category and add the name of the account
                        you would like to track.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'spendingInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'startMonthUpdateOpen')} open={this.state.startMonthUpdateOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'startMonthUpdateOpen')} />
                        <p>Select the month you want to update your numbers for.
                        You should update your numbers for every month of your plan.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'startMonthUpdateOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'pacingInfoOpen')} open={this.state.pacingInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'pacingInfoOpen')} />
                        <p><b>Plan Pacing</b> shows you how far you've come towards
                        the goals set on the Plan tab.</p>
                        <p>If you get too far off track, you can always update your
                        plan to ensure your goals remain challenging, but achievable.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'pacingInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'breakdownInfoOpen')} open={this.state.breakdownInfoOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'breakdownInfoOpen')} />
                        <p><b>Plan Breakdown</b> helps you visualize the relative
                        contribution of income sources, savings accounts, and
                        spending categories (or accounts).</p>
                        <p>Hover over each wedge to see more detail below each
                        graph.</p>
                        <button className="primary" onClick={(e) => this.toggleDialog(e, 'breakdownInfoOpen')}>Ok</button>
                    </Dialog>

                    <Dialog onClose={(e) => this.toggleDialog(e, 'resetPlanOpen')} open={this.state.resetPlanOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'resetPlanOpen')} />
                        <h1 className="solo">Warning</h1>
                        <p>Editing your plan's timeframe will clear out any data previously entered on the Update tab for all months
                        and effectively reset your plan.</p>
                        <p>Are you sure you want to reset your plan timeframe and clear data?</p>
                        <div className="actions">
                            <button className="secondary" onClick={(e) => this.toggleDialog(e, 'resetPlanOpen')}>Cancel</button>
                            <button className="primary" onClick={this.resetPlan}>Yes, Reset Plan</button>
                        </div>
                    </Dialog>

                    {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                    <Footer />
                </div>
            );
        }
    }
}

export default compose(
    withStyles({ ...DialogStyles, ...TooltipStyles }),
    connect(mapStateToProps, mapDispatchToProps)
)(CashflowGoals);
