
export const calculateAverage = (low, high) => {
    if (low && !high) {
        return Number(low);
    } else if (!low && high) {
        return Number(high);
    } else if (low && high) {
        return (Number(low) + Number(high)) / 2;
    } else {
        return 0;
    }
};

export const stripNaN = (val) => {
    if (val === 'NaN') {
        return '';
    }
}
