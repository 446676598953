import React, { Component } from 'react';
import Footer from 'components/Footer';
import Logo from 'images/icons/logo.svg';
import '../Signup/Signup.scss';

class SignupSuccess extends Component {
    render() {
        return (
            <div className="wrapper">
                <div className="login-signup-header">
                    <div className="subhead">
                        <a href="https://www.moneyswell.com"><img src={Logo} className="logo" alt="MoneySwell logo" /></a>
                        <a href="/login" className="primary">Log In</a>
                    </div>
                    <h1>Congratulations!</h1>
                </div>

                <div className="main signup success">
                    <h2>You have successfully signed up for MoneySwell! Please check your email to verify your account.</h2>
                    <p>Already verified your email? <a href="/login">Log in</a>.</p>
                </div>

                <Footer />
            </div>
        );
    }
}

export default SignupSuccess;
