import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import { loadAccount } from 'actions/accountActions';
import { getAccessTokenAndCreateAccounts } from 'actions/plaidActions';

const OAuth = () => {
    // The Link token from the first Link initialization //
    const linkToken = localStorage.getItem('msLinkToken');

    const dispatch = useDispatch();
    const account = dispatch(loadAccount());
    const assets = useSelector((state) => state.data.assets);
    const liabilities = useSelector((state) => state.data.liabilities);

    const { open, ready } = usePlaidLink({
        token: linkToken,
        receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
        onSuccess: React.useCallback((token, metadata) => {
            dispatch(getAccessTokenAndCreateAccounts(account.user, metadata));
        }, [account.user, dispatch]),
        onExit: (err, metadata) => {
            console.log(err);
            console.log(metadata);
        },
    });

    // Automatically reinitialize Link //
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    // Redirect if assets or liabilities change //
    useEffect(() => {
        if (assets || liabilities) {
            window.location.href = '/assetsLiabilities';
        }
    }, [assets, liabilities]);

    return <><h1>Finalizing OAuth Plaid Link...</h1></>;
};

export default OAuth;
