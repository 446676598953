import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogStyles from 'styles/DialogStyles.js';

class ComingSoonDialog extends Component {
    static propTypes = {
        open: PropTypes.bool,
        toggle: PropTypes.func,
    };

    render() {
        const { open, toggle } = this.props;

        return (
            <Dialog onClose={toggle} open={open} className={this.props.classes.Dialog}>
                <span className="close-dialog" onClick={toggle}>X</span>
                <p>Unfortunately this feature is still in development. Check back soon!</p>
                <button onClick={toggle}>Ok</button>
            </Dialog>
        );
    }
}

export default withStyles(DialogStyles)(ComingSoonDialog);
