/**
 * Chargebee Service
 */

import http from '../utils/http';

class chargebeeService {
    static async submitPayment(payment) {
        return http.post(`/api/chargebee/payment`, payment).then(res => res.body);
    }

    static async changePlanType(user) {
        return http.post(`/api/chargebee/subscription`, user).then(res => res.body);
    }

    static async applyDiscountCode(discountCode) {
        return http.post(`/api/chargebee/discount`, {codeId: discountCode}).then(res => res.body);
    }
}

export default chargebeeService;
