
const styles = {
    Tooltip: {
        '& .MuiTooltip-tooltip': {
            width: '150px',
            backgroundColor: '#FFF',
            color: '#585757',
            fontSize: '14px',
            textAlign: 'center',
            border: '2px solid #378088',
        },
    },
};

export default styles;
