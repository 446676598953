export const TOOLTIP_TEXT = {
    startEnd: 'Most plans should be for one year. But you can create a plan as short as a month and as long as a year.',
    linkedAccounts: 'You can download your transactions from linked accounts. To track transactions from non-linked accounts, add those transactions manually.',
    expectedNetIncome: 'Net Income is the amount that actually gets deposited into your account once taxes are taken out.',
    expectedNetSavings: <span>Net Savings is typically intended for going toward an emergency fund or something similar.<br /><br />It is NOT intended for pre-tax savings (e.g. pre-tax dollars invested in a 401k plan, etc).</span>,
    savingsDescription: <span>Each line item should correspond to a specific transation in a savings account.<br /><br />This might be regular automatic transfers or an average amount for anticipated savings over the course of your plan.<br /><br />If you have transfers for specific savings goals, e.g. "New Car", you could use that label too.</span>,
    averageMonthlySavings: <span>This is the amount you would typically transfer to your savings account in a month.<br /><br />If the savings is irregular, add a label like "Irregular Savings", estimate the total amount you hope to save over the length of your plan, and divide that by the number of months in your plan to find the average monthly.</span>,
    expectedSpending: <span>Tracking by account means just listing the accounts you spend from.<br /><br />Tracking by category is more detailed.</span>,
    accountCategory: `Enter an account you spend money from and/or any specific categories you're interested in tracking.`,
    averageMonthlySpending: <span>This is the total amount you would typically spend in a month.<br /><br />If it is a Savings Withdrawal item (e.g. a new car, home repair, or other significant expense), enter the total amount you will fund from savings.</span>,
    savingsWithdrawal: <span>A Savings Withdrawal is any expense you expect to pay for during your plan that is paid for with previously-saved income instead of your regular monthly income.<br /><br />"Savings Withdrawals" may be withdrawals from a savings OR a checking account.<br /><br />Often these are significant one-off costs like a vacation, new car, or home repair.<br /><br />Savings Withdrawals are tracked separately from regular monthly expenses and won't affect your Budget Balance Check (below).</span>,
    budgetBalanceCheck: <span>Budget Balance Check confirms if your plan for Saving and Spending is balanced with your expected monthly income.<br /><br />If you have any Savings Withdrawals, they are not factored into this check, since they are paid for with previously-saved income.<br /><br />A perfectly-balanced plan will display a half-circle graph below.<br /><br />An imbalanced plan will extend below the gray "Balance Line".</span>,
    updateMonth: 'The months in the dropdown below are based on your Plan Timeframe.',
    syncCategorizeTransactions: 'The gray account boxes that show below are the ones you opted to include on the Plan tab when you built this plan.',
    syncDisabled: 'To sync transactions from your linked bank accounts, first select the accounts you would like to sync from the Plan tab under "Plan Timeframe & Accounts to Sync."',
    accountTips: {
        'savings': <span>Since this is a savings account, transactions will generally be from your Savings categories.<br /><br />Negative transactions associated with a savings category will reduce your progress toward that category's goal.<br /><br /></span>,
        'checking': <span>Since this is a checking account, most transactions will be from your Income or Spending categories.<br /><br />Savings Withdrawal transactions will get the orange spending label.<br /><br />Transfers to your other accounts (e.g. Savings, Credit Card, etc.) should generally be ignored since those will be accounted for in those accounts.</span>,
        'credit card': <span>Since this is a credit card account, most transactions will be from your Spending categories.<br /><br />Credit card payments should generally be ignored since that spending was already accounted for in the previous month with individual transactions.<br /><br />Negative transactions (e.g. for returned items or other credits) will reduce your total spending for the assigned categories.</span>,
    },
    ignore: {
        'savings': {
            positive: `It's rare to ignore transactions into or out of a savings account unless it's for a temporary deposit or withdrawal that will soon be negated.`,
            negative: `It's rare to ignore transactions into or out of a savings account unless it's for a temporary deposit or withdrawal that will soon be negated.`,
        },
        'checking': {
            positive: 'Deposits or transfers from a savings or checking account tracked in MoneySwell should be ignored. They are not true representations of net income.',
            negative: 'Transfers to savings accounts and credit card payments should be ignored. These transfers are accounted for in those accounts.',
            },
        'credit card': {
            positive: <span>Credit card payment transactions should generally be ignored.<br /><br />That spending happened in the previous month and was already accounted for with individual transactions.</span>,
            negative: 'Purchase transactions should either be assigned to your default spending category for this account, or a specific spending category you choose to track.',
        },
    },
    manualTransactions: <span>If you plan to track cash transactions, make sure to ignore cash withdrawals from your checking account. This ensures you don't count that spending twice.<br /><br />If most of your cash spending is for the same category (e.g. "Dining Out"), we suggest labeling the withdrawal in the checking account and not adding specific manual transactions.</span>,
    summaryReview: 'When all your transactions are updated at the end of the month, select "Mark Complete" at the bottom of the Income, Savings, and Spending sections.',
    planPacing: <span>If Income or Savings are within the current month or ahead, the bar will be green. If either are behind the current month, the bar will be red.<br /><br />If spending is within the current month or behind, it will display green. If spending is ahead of the current month, it will display red.</span>,
    monthlyComparison: 'Compare your month-over-month Income, Saving, & Spending numbers.',
    cashflowBreakdown: <span>Compare the relative size of your categories within Income, Savings, and Spending.<br /><br />Note that planned Savings Withdrawals are not included in the Spending pie graph.</span>,
};

export const EMPTY_ROWS = {
    income: { source: '', frequency: 0, amount: '', low: '', high: '', notes: '' },
    savings: { source: '', amount: '', notes: '' },
    spending: { category: '', amount: '', notes: '', checked: false },
    manualTransactions: { date: null, name: '', amount: '', label: '' },
};

export const PLAN_COLORS = ['#66D8DF', '#8878F7', '#FFA34F'];

export const VALID_ACCOUNT_TYPES = ['credit card', 'savings', 'checking'];
