import React, { Component } from 'react';
import compose from 'recompose/compose';
import moment from 'moment';
import IdleTimer from 'react-idle-timer'
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { TRIAL_STATUS, TRIAL_DAYS } from 'constants/config';
import Logo from 'images/icons/logo.svg';
import HamburgerIcon from 'images/icons/hamburger.svg';
import CloseIcon from 'images/icons/close.svg';
import DialogStyles from 'styles/DialogStyles.js';
import '../NavBar/NavBar.scss';

const INITIAL_TIMEOUT = 60000 * 15; // 15 minutes
const MODAL_TIMEOUT = 30000; // 30 seconds
const SLEEP_TIMEOUT = 20000; // 20 seconds

class NavBarLoggedIn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            timeout: INITIAL_TIMEOUT,
            showModal: false,
            isTimedOut: false,
            mobileMenuOpen: false,
        };

        this.idleTimer = null;

        let self = this;
        let lastTime = (new Date()).getTime();
        setInterval(function() {
            let currentTime = (new Date()).getTime();

            if (currentTime > (lastTime + INITIAL_TIMEOUT)) {
                console.log('Logging you out');
                self.handleLogout();
            }

            lastTime = currentTime;
        }, SLEEP_TIMEOUT);
    }

    handleOnAction = (event) => {
        this.setState({ isTimedOut: false });
    }

    handleOnActive = (event) => {
        this.setState({ isTimedOut: false });
    }

    handleOnIdle = (event) => {
        if (this.state.isTimedOut) {
            this.handleLogout();
        } else {
            this.idleTimer.reset();
            this.setState({
                showModal: true,
                timeout: MODAL_TIMEOUT,
                isTimedOut: true,
            });
        }
    }

    handleClose = () => {
        this.setState({
            showModal: false,
            timeout: INITIAL_TIMEOUT,
        });
    }

    handleLogout = () => {
        window.location.href = '/logout?timeout=1';
    }

    toggleMobileMenu = () => {
        this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
    }

    defaultNavClick = (e, tierId) => {
        e.preventDefault();
        window.location.href = `/plan?t=${tierId}`;
    }

    render() {
        const { timeout, showModal, mobileMenuOpen } = this.state;
        const { classes, account, fp, onFP, tierId, onNavClick, rp, alBadge } = this.props;
        const navClick = (onNavClick === null) ? this.defaultNavClick : onNavClick;
        const p = window.location.pathname.split('/')[1];

        // Calculate FPAP progress //
        const visibleTasks = fp?.tasks.filter(t => t.visible).length;
        const completedTasks = fp?.tasks.filter(t => t.status).length;
        let fpapProgress = null;
        if (visibleTasks && completedTasks) {
            const percent = Math.round((completedTasks / visibleTasks) * 100);
            fpapProgress = (
                <div className="status">
                    <div className="bar">
                        <div className="progress" style={{width: `${percent}%`}} />
                    </div>
                    <label>{`${percent}%`}</label>
                </div>
            );
        }

        // Calculate trial period remaining //
        let trialRemaining = null;
        if (account?.user?.currentSubscription?.status === TRIAL_STATUS && !account?.user?.card) {
            const diff = Math.abs(moment(account.user.currentSubscription.trial_end * 1000).diff(moment(), 'days') + 1);
            const percent = Math.round(((TRIAL_DAYS - diff) / TRIAL_DAYS) * 100);
            trialRemaining = (
                <div className="status trial">
                    <div className="bar">
                        <div className="progress" style={{width: `${100 - percent}%`}} />
                    </div>
                    <label>{`${diff} Days`}</label>
                </div>
            );
        }

        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={timeout}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />

                <nav className={cn('nav', mobileMenuOpen && 'mobile-menu-open', onFP && 'on-fp')}>
                    <section>
                        <div className="nav-header">
                            <a href="/dashboard"><img src={Logo} className="logo" alt="MoneySwell logo" /></a>
                            <h2>MoneySwell</h2>
                            {(mobileMenuOpen) ?
                                <img src={CloseIcon} className="menu-icon" alt="Close icon, X, grey" onClick={this.toggleMobileMenu} /> :
                                <img src={HamburgerIcon} className="menu-icon" alt="Hamburger icon, 3 bars, grey" onClick={this.toggleMobileMenu} />
                            }
                        </div>

                        <div className={cn('links-wrapper', mobileMenuOpen && 'mobile-menu-open')}>
                            <ul className="nav-links">
                                <li className={cn('nav-item', p === 'dashboard' && 'selected')}><a href="/dashboard">Dashboard</a></li>
                                <li className="nav-item section">Action Plans</li>
                                <li className={cn('nav-item sub', p === 'plan' && 'selected')}>
                                    <a href="/plan">
                                        Financial Priorities {fpapProgress}
                                    </a>
                                </li>
                                {onFP && <>
                                    {fp.tiers.map((tier, i) => {
                                        return (
                                            <li key={i} className={cn('nav-item secondary', tierId === tier.id && 'selected')}>
                                                <a href="noop" onClick={(e) => navClick(e, tier.id)}>{tier.name}</a>
                                            </li>
                                        );
                                    })}
                                </>}
                                <li className="nav-item section">Tools</li>
                                <li className={cn('nav-item sub', p === 'assetsLiabilities' && 'selected')}>
                                    <a href="/assetsLiabilities">
                                        Assets & Liabilities
                                        {alBadge > 0 && <span className="badge">{alBadge}</span>}
                                    </a>
                                </li>
                                <li className={cn('nav-item sub', p === 'budgetPlanner' && 'selected')}><a href="/budgetPlanner">Budget Planner</a></li>
                                {false && <li className={cn('nav-item sub', p === 'cashflowGoals' && 'selected')}><a href="/cashflowGoals?tab=review">Cashflow Goals</a></li>}
                                <li className={cn('nav-item sub', p === 'retirement' && 'selected')}>
                                    <a href="/retirement">
                                        Retirement Planner
                                        {rp > 0 && <span className="badge">{rp}</span>}
                                    </a>
                                </li>
                            </ul>

                            <div className="divider" />

                            <ul className="nav-links">
                                <li className="nav-item"><a href="https://moneyswell.com/blog" target="_blank" rel="noreferrer">Blog</a></li>
                                <li className={cn('nav-item', p === 'account' && 'selected')}><a href="/account">My Account</a></li>
                                {trialRemaining &&
                                    <li className={cn('nav-item sub extra-padding', p === 'account' && 'selected')}>
                                        <a href="/subscriptionSettings">
                                            Free Trial Remaining {trialRemaining} <span>Sign Up Now!</span>
                                        </a>
                                    </li>
                                }
                                <li className="nav-item"><a href="/logout">Logout</a></li>
                            </ul>
                        </div>
                    </section>
                </nav>

                <Dialog onClose={this.handleClose} open={showModal} className={classes.Dialog}>
                    <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.handleClose} />
                    <h1>You are about to be logged out</h1>
                    <p>For your security, you will automatically be logged out in 30 seconds.</p>
                    <div className="actions">
                        <button className="secondary" onClick={this.handleLogout}>Logout Now</button>
                        <button className="primary" onClick={this.handleClose}>Continue Session</button>
                    </div>
                </Dialog>
            </>
        );
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
)(NavBarLoggedIn);
