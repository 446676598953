import {
    ADD_ACCOUNT_ERROR,
    ADD_ACCOUNT_SUCCESS,
    ADD_TOPIC_ERROR,
    ADD_TOPIC_SUCCESS,
    ASSETS_LIABILITIES_LOAD_ERROR,
    ASSETS_LIABILITIES_LOAD_SUCCESS,
    CASHFLOW_ACTUAL_LOAD_ERROR,
    CASHFLOW_ACTUAL_LOAD_SUCCESS,
    CASHFLOW_GOALS_LOAD_ERROR,
    CASHFLOW_GOALS_LOAD_SUCCESS,
    CASHFLOW_REVIEW_LOAD_ERROR,
    CASHFLOW_REVIEW_LOAD_SUCCESS,
    DELETE_ACCOUNT_ERROR,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_CASHFLOW_GOALS_ERROR,
    DELETE_CASHFLOW_GOALS_SUCCESS,
    DELETE_CASHFLOW_PLAN_ERROR,
    DELETE_CASHFLOW_PLAN_SUCCESS,
    DELETE_HISTORICAL_PROGRESS_ERROR,
    DELETE_HISTORICAL_PROGRESS_SUCCESS,
    EDIT_ACCOUNT_ERROR,
    EDIT_ACCOUNT_SUCCESS,
    FINANCIAL_PRIORITIES_LOAD_ERROR,
    FINANCIAL_PRIORITIES_LOAD_SUCCESS,
    HISTORICAL_LOAD_ERROR,
    HISTORICAL_LOAD_SUCCESS,
    LOAD_TASK_ERROR,
    LOAD_TASK_SUCCESS,
    LOAD_TASK_FROM_LINK_ERROR,
    LOAD_TASK_FROM_LINK_SUCCESS,
    REMOVE_RETIREMENT_PLANNER_INCOME_ERROR,
    REMOVE_RETIREMENT_PLANNER_INCOME_SUCCESS,
    RETIREMENT_PLANNER_LOAD_ERROR,
    RETIREMENT_PLANNER_LOAD_SUCCESS,
    RETIREMENT_PLANNER_GOALS_LOAD_ERROR,
    RETIREMENT_PLANNER_GOALS_LOAD_SUCCESS,
    RETIREMENT_PLANNER_GOALS_ALL_LOAD_ERROR,
    RETIREMENT_PLANNER_GOALS_ALL_LOAD_SUCCESS,
    RETIREMENT_PLANNER_GOAL_YEARS_LOAD_ERROR,
    RETIREMENT_PLANNER_GOAL_YEARS_LOAD_SUCCESS,
    RETIREMENT_PLANNER_INCOME_LOAD_ERROR,
    RETIREMENT_PLANNER_INCOME_LOAD_SUCCESS,
    SAVE_CASHFLOW_GOALS_ERROR,
    SAVE_CASHFLOW_GOALS_SUCCESS,
    SAVE_HISTORICAL_RECORD_ERROR,
    SAVE_HISTORICAL_RECORD_SUCCESS,
    SAVE_RETIREMENT_PLANNER_ERROR,
    SAVE_RETIREMENT_PLANNER_SUCCESS,
    SAVE_RETIREMENT_PLANNER_GOALS_ERROR,
    SAVE_RETIREMENT_PLANNER_GOALS_SUCCESS,
    SAVE_RETIREMENT_PLANNER_INCOME_ERROR,
    SAVE_RETIREMENT_PLANNER_INCOME_SUCCESS,
    SAVE_SURVEY_ERROR,
    TOGGLE_TASK_ERROR,
    TOGGLE_TASK_SUCCESS,
} from '../constants/actionTypes';
import dataService from '../services/dataService';

export const loadAssetsLiabilities = (user) => dispatch => (
    dataService.loadAssetsLiabilities(user)
        .then(res => {
            dispatch({
                type: ASSETS_LIABILITIES_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: ASSETS_LIABILITIES_LOAD_ERROR,
                payload: e,
            });
        })
);

export const saveHistoricalRecord = (user, today, accounts) => dispatch => (
    dataService.saveHistoricalRecord(user, today, accounts)
        .then(res => {
            dispatch({
                type: SAVE_HISTORICAL_RECORD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SAVE_HISTORICAL_RECORD_ERROR,
                payload: e,
            });
        })
);

export const addAccount = (user, account) => dispatch => (
    dataService.addAccount(user, account)
        .then(res => {
            dispatch({
                type: ADD_ACCOUNT_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: ADD_ACCOUNT_ERROR,
                payload: e,
            });
        })
);

export const editAccount = (user, account) => dispatch => (
    dataService.editAccount(user, account)
        .then(res => {
            dispatch({
                type: EDIT_ACCOUNT_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: EDIT_ACCOUNT_ERROR,
                payload: e,
            });
        })
);

export const deleteAccount = (user, account) => dispatch => (
    dataService.deleteAccount(user, account)
        .then(res => {
            dispatch({
                type: DELETE_ACCOUNT_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DELETE_ACCOUNT_ERROR,
                payload: e,
            });
        })
);

export const loadHistoricalData = (userId, startDate, endDate, updatePrefs, breakdown) => dispatch => (
    dataService.loadHistoricalData(userId, startDate, endDate, updatePrefs, breakdown)
        .then(res => {
            dispatch({
                type: HISTORICAL_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: HISTORICAL_LOAD_ERROR,
                payload: e,
            });
        })
);

export const loadHistoricalDay = (user, date) => dispatch => (
    dataService.loadHistoricalDay(user, date)
        .then(res => {
            dispatch({
                type: HISTORICAL_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: HISTORICAL_LOAD_ERROR,
                payload: e,
            });
        })
);

export const saveHistoricalDay = (user, accounts, date) => dispatch => (
    dataService.saveHistoricalDay(user, accounts, date)
        .then(res => {
            window.location.href = '/assetsLiabilities?t=Historical';
        })
        .catch(e => {
            dispatch({
                type: SAVE_HISTORICAL_RECORD_ERROR,
                payload: e,
            });
        })
);

export const loadCashflowGoals = (user) => dispatch => (
    dataService.loadCashflowGoals(user)
        .then(res => {
            dispatch({
                type: CASHFLOW_GOALS_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: CASHFLOW_GOALS_LOAD_ERROR,
                payload: e,
            });
        })
);

export const saveCashflowGoals = (user, plan) => dispatch => (
    dataService.saveCashflowGoals(user, plan)
        .then(res => {
            dispatch({
                type: SAVE_CASHFLOW_GOALS_SUCCESS,
                payload: res,
            });
            if (!res.actual) window.location.href = '/cashflowGoals';
        })
        .catch(e => {
            dispatch({
                type: SAVE_CASHFLOW_GOALS_ERROR,
                payload: e,
            });
        })
);

export const deleteCashflowGoals = (user, id, type) => dispatch => (
    dataService.deleteCashflowGoals(user, id, type)
        .then(res => {
            dispatch({
                type: DELETE_CASHFLOW_GOALS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DELETE_CASHFLOW_GOALS_ERROR,
                payload: e,
            });
        })
);

export const loadCashflowActual = (user, month, planId) => dispatch => (
    dataService.loadCashflowActual(user, month, planId)
        .then(res => {
            dispatch({
                type: CASHFLOW_ACTUAL_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: CASHFLOW_ACTUAL_LOAD_ERROR,
                payload: e,
            });
        })
);

export const loadCashflowReview = (user, planId) => dispatch => (
    dataService.loadCashflowReview(user, planId)
        .then(res => {
            dispatch({
                type: CASHFLOW_REVIEW_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: CASHFLOW_REVIEW_LOAD_ERROR,
                payload: e,
            });
        })
);

export const loadFinancialPriorities = (user) => dispatch => (
    dataService.loadFinancialPriorities(user)
        .then(res => {
            dispatch({
                type: FINANCIAL_PRIORITIES_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: FINANCIAL_PRIORITIES_LOAD_ERROR,
                payload: e,
            });
        })
);

export const toggleTask = (task, user) => dispatch => (
    dataService.toggleTask(task, user)
        .then(res => {
            dispatch({
                type: TOGGLE_TASK_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: TOGGLE_TASK_ERROR,
                payload: e,
            });
        })
);

export const addTopic = (topic, user) => dispatch => (
    dataService.addTopic(topic, user)
        .then(res => {
            dispatch({
                type: ADD_TOPIC_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: ADD_TOPIC_ERROR,
                payload: e,
            });
        })
);

export const loadTask = (user, taskId) => dispatch => (
    dataService.loadTask(user, taskId)
        .then(res => {
            dispatch({
                type: LOAD_TASK_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: LOAD_TASK_ERROR,
                payload: e,
            });
        })
);

export const loadTaskFromLink = (url, user) => dispatch => (
    dataService.loadTaskFromLink(url, user)
        .then(res => {
            dispatch({
                type: LOAD_TASK_FROM_LINK_SUCCESS,
                payload: res,
            });
            window.open(`/task?tid=${res.task.id}`, '_blank');
        })
        .catch(e => {
            dispatch({
                type: LOAD_TASK_FROM_LINK_ERROR,
                payload: e,
            });
        })
);

export const saveSurvey = (user, survey) => dispatch => (
    dataService.saveSurvey(user, survey)
        .then(res => {
            window.location.href = '/plan';
        })
        .catch(e => {
            dispatch({
                type: SAVE_SURVEY_ERROR,
                payload: e,
            });
        })
);

export const deleteCashflowPlan = (userId, planId) => dispatch => (
    dataService.deleteCashflowPlan(userId, planId)
        .then(res => {
            dispatch({
                type: DELETE_CASHFLOW_PLAN_SUCCESS,
                payload: res,
            });
            window.location.href = '/cashflowGoals';
        })
        .catch(e => {
            dispatch({
                type: DELETE_CASHFLOW_PLAN_ERROR,
                payload: e,
            });
        })
);

export const loadRetirementPlanner = (user) => dispatch => (
    dataService.loadRetirementPlanner(user)
        .then(res => {
            dispatch({
                type: RETIREMENT_PLANNER_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: RETIREMENT_PLANNER_LOAD_ERROR,
                payload: e,
            });
        })
);

export const saveRetirementPlanner = (user, fields) => dispatch => (
    dataService.saveRetirementPlanner(user, fields)
        .then(res => {
            dispatch({
                type: SAVE_RETIREMENT_PLANNER_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SAVE_RETIREMENT_PLANNER_ERROR,
                payload: e,
            });
        })
);

export const loadRPIncome = (user, rpId) => dispatch => (
    dataService.loadRPIncome(user, rpId)
        .then(res => {
            dispatch({
                type: RETIREMENT_PLANNER_INCOME_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: RETIREMENT_PLANNER_INCOME_LOAD_ERROR,
                payload: e,
            });
        })
);

export const saveRPIncome = (user, income, rpId) => dispatch => (
    dataService.saveRPIncome(user, income, rpId)
        .then(res => {
            dispatch({
                type: SAVE_RETIREMENT_PLANNER_INCOME_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SAVE_RETIREMENT_PLANNER_INCOME_ERROR,
                payload: e,
            });
        })
);

export const removeRPIncome = (user, income) => dispatch => (
    dataService.removeRPIncome(user, income)
        .then(res => {
            dispatch({
                type: REMOVE_RETIREMENT_PLANNER_INCOME_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: REMOVE_RETIREMENT_PLANNER_INCOME_ERROR,
                payload: e,
            });
        })
);

export const loadRPGoals = (user, rpId, year, accountIds) => dispatch => (
    dataService.loadRPGoals(user, rpId, year, accountIds)
        .then(res => {
            dispatch({
                type: RETIREMENT_PLANNER_GOALS_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: RETIREMENT_PLANNER_GOALS_LOAD_ERROR,
                payload: e,
            });
        })
);

export const saveRPGoals = (user, goals, rpId) => dispatch => (
    dataService.saveRPGoals(user, goals, rpId)
        .then(res => {
            dispatch({
                type: SAVE_RETIREMENT_PLANNER_GOALS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SAVE_RETIREMENT_PLANNER_GOALS_ERROR,
                payload: e,
            });
        })
);

export const loadRPGoalYears = (user, rpId) => dispatch => (
    dataService.loadRPGoalYears(user, rpId)
        .then(res => {
            dispatch({
                type: RETIREMENT_PLANNER_GOAL_YEARS_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: RETIREMENT_PLANNER_GOAL_YEARS_LOAD_ERROR,
                payload: e,
            });
        })
);

export const loadRPGoalsAll = (user, rpId, accountIds) => dispatch => (
    dataService.loadRPGoalsAll(user, rpId, accountIds)
        .then(res => {
            dispatch({
                type: RETIREMENT_PLANNER_GOALS_ALL_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: RETIREMENT_PLANNER_GOALS_ALL_LOAD_ERROR,
                payload: e,
            });
        })
);

export const deleteHP = (user, hpId) => dispatch => (
    dataService.deleteHP(user, hpId)
        .then(res => {
            dispatch({
                type: DELETE_HISTORICAL_PROGRESS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DELETE_HISTORICAL_PROGRESS_ERROR,
                payload: e,
            });
        })
);
