export const slideData = [
    {
        header: "Achieving your financial goals can be challenging. But <em>it shouldn't be confusing</em>.",
        body: "This action plan is your roadmap to financial success. It's based on time-tested fundamentals and best practices. It has been reviewed by Certified Financial Planners.",
        alt: "Stylized illustration, silhouette of a person climbing a mountain with a walking stick, other mountains in the background, birds flying",
    },
    {
        header: "The key to success is <em>persistence</em>, <em>continuous learning</em>, and <em>action</em>.",
        body: "Your circumstances and challenges are unique. But you're smart and capable. And as you learn and follow-through, you will see your financial health improve.",
        alt: "Stylized illustration, yellow moon rising over teal mountains, trees in foreground, night sky partly cloudy with stars",
    },
    {
        header: "Your plan ensures you always know <em>what to do next to make progress</em>.",
        body: "A checklist is a surprisingly powerful tool. Your plan combines quick and easy tasks with longer-term milestones to keep you focused, and on track.",
        alt: "Stylized illustration, person hiking a cliff trail next to some trees, sun low over mountains in background, birds flying",
    },
    {
        header: "You'll move through tiers of financial health with <em>tasks customized for you</em>.",
        body: "Every plan starts in Foundations and moves through Abundance. But the tasks in your plan have beeen customized for you based on on your circumstances.",
        alt: "Stylized illustration, bungalows over the ocean, palm trees, night sky, crescent moon, shooting star, birds flying, still water",
    },
    {
        header: "As your financial health improves, you can <em>improve your life and impact the world</em> around you.",
        body: "Money is a tool and a resource, not the end goal. Understood, and used wisely, it can help you lead the life you dream of, and be a force for good.",
        alt: "Stylized illustration, yellow sun setting over a teal ocean, pink and orange sky, palm tree silhouettes in foreground, birds flying",
    },
    {
        header: "<em>Start today</em>, take your time, and believe in progress. <em>You got this!</em>",
        body: "Click into your first task in the Next Steps section below. Or, browse through the topics in the Foundations tier and start checking items off your list.",
        alt: "Stylized illustration, two people paddle boarding on a yellow lake, trees in foreground, island in background, mountains beyond, orange sky",
    },
];
