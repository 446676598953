import Abundance from './abundance.svg';
import CharitableGivingPhilanthropy from './charitable_giving_and_philanthropy.svg';
import Children from './children.svg';
import EmergencyFund from './emergency_fund.svg';
import FinancialAutomation from './financial_automation.svg';
import FinancialConcepts from './financial_concepts.svg';
import FinancialPlanner from './financial_planner.svg';
import FinancialTracking from './financial_tracking.svg';
import Foundations from './foundations.svg';
import Growth from './growth.svg';
import HealthInsurance from './health_insurance.svg';
import HighinterestDebt from './high_interest_debt.svg';
import IncomeEssentialExpenses from './income_essential_expenses.svg';
import Independence from './independence.svg';
import Insurance from './insurance.svg';
import LongtermSavings from './long_term_savings.svg';
import MediuminterestDebt from './medium_interest_debt.svg';
import MoneyMindset from './money_mindset.svg';
import OnlineOfflineSecurity from './online_offline_security.svg';
import OtherDebt from './other_debt.svg';
import OtherInvestments from './other_investments.svg';
import ProfileInformation from './profile_information.svg';
import Security from './security.svg';
import Taxes from './taxes.svg';
import UnderConstruction from './under_construction.svg';
import WillandTrusts from './will_and_trusts.svg';
import YourActionPlan from './profile_information.svg';

export const categoryIcons = {
    CharitableGivingPhilanthropy,
    Children,
    EmergencyFund,
    FinancialAutomation,
    FinancialConcepts,
    FinancialPlanner,
    FinancialTracking,
    HealthInsurance,
    HighinterestDebt,
    IncomeEssentialExpenses,
    Insurance,
    LongtermSavings,
    MediuminterestDebt,
    MoneyMindset,
    OnlineOfflineSecurity,
    OtherDebt,
    OtherInvestments,
    ProfileInformation,
    Taxes,
    UnderConstruction,
    WillandTrusts,
    YourActionPlan,
};

export const tierIcons = {
    Abundance,
    Foundations,
    Growth,
    Independence,
    Security,
};
