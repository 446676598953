import {
    DELETE_BUDGET_PLAN_ERROR,
    DELETE_BUDGET_PLAN_SUCCESS,
    DELETE_TRANSACTION_ERROR,
    DELETE_TRANSACTION_SUCCESS,
    EDIT_BUDGET_PLAN_ERROR,
    EDIT_BUDGET_PLAN_SUCCESS,
    EDIT_BUDGET_PLAN_MONTH_ERROR,
    EDIT_BUDGET_PLAN_MONTH_SUCCESS,
    EDIT_TRANSACTIONS_ERROR,
    EDIT_TRANSACTIONS_SUCCESS,
    LOAD_BUDGET_PLAN_ERROR,
    LOAD_BUDGET_PLAN_SUCCESS,
    LOAD_BUDGET_PLAN_MONTH_ERROR,
    LOAD_BUDGET_PLAN_MONTH_SUCCESS,
    LOAD_BUDGET_PLAN_REVIEW_ERROR,
    LOAD_BUDGET_PLAN_REVIEW_SUCCESS,
    SAVE_BUDGET_PLAN_ERROR,
    SAVE_BUDGET_PLAN_SUCCESS,
    UPDATE_PLAN_MONTH_CATEGORY_ERROR,
    UPDATE_PLAN_MONTH_CATEGORY_SUCCESS,
} from '../constants/actionTypes';
import budgetPlanService from '../services/budgetPlanService';

export const loadBudgetPlan = (user) => dispatch => (
    budgetPlanService.loadBudgetPlan(user)
        .then(res => {
            dispatch({
                type: LOAD_BUDGET_PLAN_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: LOAD_BUDGET_PLAN_ERROR,
                payload: e,
            });
        })
);

export const saveBudgetPlan = (user, plan) => dispatch => (
    budgetPlanService.saveBudgetPlan(user, plan)
        .then(res => {
            dispatch({
                type: SAVE_BUDGET_PLAN_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SAVE_BUDGET_PLAN_ERROR,
                payload: e,
            });
        })
);

export const editBudgetPlan = (user, rowId, type) => dispatch => (
    budgetPlanService.editBudgetPlan(user, rowId, type)
        .then(res => {
            dispatch({
                type: EDIT_BUDGET_PLAN_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: EDIT_BUDGET_PLAN_ERROR,
                payload: e,
            });
        })
);

export const deleteBudgetPlan = (user, plan) => dispatch => (
    budgetPlanService.deleteBudgetPlan(user, plan)
        .then(res => {
            dispatch({
                type: DELETE_BUDGET_PLAN_SUCCESS,
                payload: res,
            });
            window.location.href = '/budgetPlanner';
        })
        .catch(e => {
            dispatch({
                type: DELETE_BUDGET_PLAN_ERROR,
                payload: e,
            });
        })
);

export const loadBudgetPlanMonth = (user, planId, month) => dispatch => (
    budgetPlanService.loadBudgetPlanMonth(user, planId, month)
        .then(res => {
            dispatch({
                type: LOAD_BUDGET_PLAN_MONTH_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: LOAD_BUDGET_PLAN_MONTH_ERROR,
                payload: e,
            });
        })
);

export const updatePlanMonthCategory = (user, category) => dispatch => (
    budgetPlanService.updatePlanMonthCategory(user, category)
        .then(res => {
            dispatch({
                type: UPDATE_PLAN_MONTH_CATEGORY_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: UPDATE_PLAN_MONTH_CATEGORY_ERROR,
                payload: e,
            });
        })
);

export const editTransactions = (user, transactions, plan) => dispatch => (
    budgetPlanService.editTransactions(user, transactions, plan)
        .then(res => {
            dispatch({
                type: EDIT_TRANSACTIONS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: EDIT_TRANSACTIONS_ERROR,
                payload: e,
            });
        })
);

export const deleteTransaction = (user, transactionId) => dispatch => (
    budgetPlanService.deleteTransaction(user, transactionId)
        .then(res => {
            dispatch({
                type: DELETE_TRANSACTION_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DELETE_TRANSACTION_ERROR,
                payload: e,
            });
        })
);

export const editBudgetPlanMonth = (user, plan) => dispatch => (
    budgetPlanService.editBudgetPlanMonth(user, plan)
        .then(res => {
            dispatch({
                type: EDIT_BUDGET_PLAN_MONTH_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: EDIT_BUDGET_PLAN_MONTH_ERROR,
                payload: e,
            });
        })
);

export const loadBudgetPlanReview = (user, planId) => dispatch => (
    budgetPlanService.loadBudgetPlanReview(user, planId)
        .then(res => {
            dispatch({
                type: LOAD_BUDGET_PLAN_REVIEW_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: LOAD_BUDGET_PLAN_REVIEW_ERROR,
                payload: e,
            });
        })
);
