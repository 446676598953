import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Loading from 'components/Loading';
import NavBar from 'components/NavBar';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, saveSurvey } from 'actions/dataActions';
import CloseIcon from 'images/icons/close.svg';
import Logo from 'images/icons/logo.svg';
import { surveyData } from './data';
import './Survey.scss';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    saveSurvey: (user, survey) => {
        dispatch(saveSurvey(user, survey));
    },
});

class Survey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            dataLoaded: false,
            step: -1,
            answers: Array(10).fill(false),
            mobile: (window.innerWidth < 1440),
        };

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        let account = nextProps.account;
        if ((!account.loaded && account.error.error) || (account.loaded && account.user)) {
            state.loaded = true;
        }

        let plan = nextProps.financialPriorities;
        if (plan && (plan.topics.length || plan.tasks.length)) {
            window.location.href = '/plan';
        } else {
            if (account.loaded && account.user && !plan) {
                this.props.loadFinancialPriorities(account.user);
            } else {
                state.dataLoaded = true;
            }
        }

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobile: (window.innerWidth < 1440) });
    }

    previousStep = (e) => {
        e.preventDefault();
        this.setState({ step: this.state.step - 1 });
    }

    answerQuestion = (e) => {
        let answers = this.state.answers;
        answers[this.state.step] = e.target.value;
        this.setState({ answers: answers });
    }

    toggleCheckbox = (e) => {
        let answers = this.state.answers, step = this.state.step;

        if (answers[step]) {
            if (e.target.checked) {
                answers[step].push(e.target.value);
            } else {
                answers[step].splice(answers[step].indexOf(e.target.value), 1);
            }
        } else {
            answers[step] = [e.target.value];
        }

        this.setState({ answers: answers });
    }

    nextStep = () => {
        const step = this.state.step, account = this.props.account;

        if (step >= 0 && !this.state.answers[step]) {
            alert('Please answer the question before proceeding');
            return;
        }

        this.setState({ step: step + 1 });

        if (step === 9) {
            setTimeout(() => {
                if (account.loaded && account.user) {
                    this.props.saveSurvey(account.user, this.state);
                } else {
                    // Store state in local storage //
                    if (typeof(Storage) !== "undefined") {
                        sessionStorage.moneyswell = JSON.stringify(Object.assign({}, this.state));
                    }

                    // Send to the create an account page //
                    window.location.href = '/signup';
                }
            }, 2000);
        }
    }

    render() {
        const { loaded, dataLoaded, step, answers, mobile } = this.state;
        const { account, financialPriorities } = this.props;

        if (!loaded || (loaded && account.loaded && !dataLoaded)) {
            return (<Loading />);
        } else {
            return (
                <div className="wrapper">
                    {(account.user) ? <NavBarLoggedIn account={account} fp={financialPriorities} /> : <NavBar />}

                    <div className="main-bg" />

                    <div className="main survey">
                        {step === -1 &&
                            <div className="intro">
                                <img src={CloseIcon} className="close-icon" alt="Close icon, X, grey" onClick={this.nextStep} />

                                <h1>Build Your Financial Priorities Action Plan</h1>
                                <h2 className="light">Your customized checklist to achieve your financial goals.</h2>

                                <ol>
                                    <li><strong>1. Start with a quick survey.</strong> Remember, your answers are 100% confidential and we never sell your data.</li>
                                    {!account.user && <li><strong>2. Choose your plan type</strong> (Monthly or Annual) and start your 34 day free trial.</li>}
                                    {account.user && <li><strong>2. Review your customized plan</strong> and track your progress towards better financial health.</li>}
                                    {!account.user && <li><strong>3. Review your customized plan</strong> and track your progress towards better financial health.</li>}
                                    {account.user && <li><strong>3. See your next steps</strong> directly in your Dashboard Agenda every time you login.</li>}
                                </ol>

                                <button className="primary" onClick={this.nextStep}>Let's Go!</button>
                            </div>
                        }

                        {step >= 0 && surveyData.map((d, i) => {
                            const percentComplete = ((step+1) / surveyData.length) * 100;

                            return (
                                <div className={cn('survey-step', step === i && 'visible')} key={i}>
                                    <div className="progress">
                                        <div className="progress-bar">
                                            <div className="pace" style={{ width: `${percentComplete}%` }} />
                                        </div>
                                        <div className="progress-details">
                                            <label><span>{percentComplete + '%'}</span> Complete</label>
                                            <span className="step-num">{`${step+1}/${surveyData.length}`}</span>
                                        </div>
                                    </div>

                                    <span className="step-num">{`${step+1}/${surveyData.length}`}</span>

                                    <h1>{d.question}</h1>

                                    {d.answers &&
                                        <FormControl component="fieldset">
                                            <RadioGroup value={answers[i]} onChange={this.answerQuestion}>
                                                {d.answers.map((a, j) => {
                                                    return (
                                                        <FormControlLabel key={j} value={a.value} control={<Radio checkedIcon={<CircleCheckedFilled />} />} label={a.label} />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    }

                                    {d.multipleChoice &&
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                {d.multipleChoice.map((mc, j) => {
                                                    const checked = answers[i] && answers[i].includes(mc.value);
                                                    return (
                                                        <FormControlLabel key={j} control={<Checkbox checked={checked} onChange={this.toggleCheckbox} value={mc.value} />} label={mc.label} />
                                                    );
                                                })}
                                            </FormGroup>
                                        </FormControl>
                                    }

                                    {!mobile &&
                                        <div className="actions">
                                            {step > 0 && <a href="noop" className="back" onClick={(e) => this.previousStep(e)}>{'< Back'}</a>}
                                            <button className="primary" onClick={this.nextStep}>{(step === 9) ? 'Finish' : 'Next'}</button>
                                        </div>
                                    }

                                    {mobile &&
                                        <div className="actions">
                                            <span className="arrow back" onClick={this.previousStep}>{'<'}</span>
                                            <span className="arrow forward" onClick={this.nextStep}>{'>'}</span>
                                        </div>
                                    }
                                </div>
                            );
                        })}

                        {step === 10 &&
                            <div className="survey-step visible">
                                <div className="progress">
                                    <div className="progress-bar">
                                        <div className="pace" style={{ width: `100%` }} />
                                    </div>
                                    <div className="progress-details">
                                        <label><span>{'100%'}</span> Complete</label>
                                    </div>
                                </div>

                                <h1 className="final">Well done! We’re building your custom Financial Priorities Action Plan. Hold tight!</h1>

                                <img src={Logo} className="logo" alt="MoneySwell logo" />
                            </div>
                        }
                    </div>

                    <Footer />
                </div>
            );
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Survey);
