import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import cn from 'classnames';
import { PieChart, Pie } from 'recharts';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import Loading from 'components/Loading';
import Footer from 'components/Footer';
import ArrowIcon from 'components/ArrowIcon';
import PlusIcon from 'components/PlusIcon';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, toggleTask, addTopic } from 'actions/dataActions';
import { STROKES } from 'constants/financialPriorities';
import { slideData } from './data';
import { categoryIcons, tierIcons } from 'images/icons';
import CloseIcon from 'images/icons/close.svg';
import OnTrackIcon from 'images/icons/on-track.svg';
import NextIcon from 'images/icons/next.png';
import RemoveIcon from 'images/icons/remove-x.svg';
import Slide1 from 'images/slides/slide1.jpg';
import Slide2 from 'images/slides/slide2.jpg';
import Slide3 from 'images/slides/slide3.jpg';
import Slide4 from 'images/slides/slide4.jpg';
import Slide5 from 'images/slides/slide5.jpg';
import Slide6 from 'images/slides/slide6.jpg';
import Tier1 from 'images/tiers/tier1.jpg';
import Tier2 from 'images/tiers/tier2.jpg';
import Tier3 from 'images/tiers/tier3.jpg';
import Tier4 from 'images/tiers/tier4.jpg';
import Tier5 from 'images/tiers/tier5.jpg';
import DialogStyles from 'styles/DialogStyles.js';
import '../Dashboard/Dashboard.scss';
import './FinancialPriorities.scss';

const SLIDE_IMAGES = [ Slide1, Slide2, Slide3, Slide4, Slide5, Slide6 ];
const TIER_IMAGES = [ Tier1, Tier2, Tier3, Tier4, Tier5 ];

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    toggleTask: (task, user) => {
        dispatch(toggleTask(task, user));
    },
    addTopic: (topic, user) => {
        dispatch(addTopic(topic, user));
    },
});

class FinancialPriorities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            dataLoaded: false,
            tierId: 0,
            categoryId: 0,
            slide: 0,
            slideshowOpen: true,
            tier: null,
            category: null,
            categories: null,
            topics: null,
            tasks: null,
            collapsedTopics: [],
            viewAllOpen: false,
            takeSurveyOpen: false,
            editingTopic: false,
            mobile: (window.innerWidth < 1440),
        };

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        let account = nextProps.account;
        if ((!account.loaded && account.error.error) || (account.loaded && account.user)) {
            state.loaded = true;
        }

        if (nextProps.financialPriorities) {
            state.dataLoaded = true;

            if (nextProps.financialPriorities.tasks.length === 0) {
                state.takeSurveyOpen = true;
            }
            setTimeout(() => {
                if (window.location.search.includes('?t=')) {
                    const params = new URL(window.location.href).searchParams;
                    if (params.get('t')) {
                        this.setTierId({}, Number(params.get('t')));
                    }
                    if (params.get('c')) {
                        this.setCategoryId(null, Number(params.get('c')));
                    }
                } else if (window.location.search.includes('?task=')) {
                    const taskId = Number(window.location.search.split('?task=')[1]);
                    const topicId = nextProps.financialPriorities.tasks.filter(t => t.id === taskId)[0].topic_id;
                    const categoryId = nextProps.financialPriorities.topics.filter(t => t.id === topicId)[0].category_id;
                    this.setCategoryId(null, categoryId);
                }
            }, 250);
        } else {
            if (account.loaded && account.user && !nextProps.success && !nextProps.error) {
                this.props.loadFinancialPriorities(account.user);
            }
        }

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobile: (window.innerWidth < 1440) });
    }

    setTierId = (e, tierId) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const fp = this.props.financialPriorities;
        const tier = fp.tiers.filter(t => t.id === tierId)[0];
        const categories = fp.categories.filter(c => c.tier_id === tierId);
        const topics = fp.topics.filter(t => categories.map(c => c.id).includes(t.category_id));
        const tasks = fp.tasks.filter(t => topics.map(t => t.id).includes(t.topic_id));

        this.setState({
            tierId: tierId,
            categoryId: 0,
            slide: 0,
            tier: tier,
            category: null,
            categories: categories,
            topics: topics,
            tasks: tasks,
        });

        window.scrollTo(0,0);
    }

    setCategoryId = (e, categoryId, unsetTier) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const fp = this.props.financialPriorities;
        const category = fp.categories.filter(c => c.id === categoryId)[0];
        const tier = fp.tiers.filter(t => t.id === category.tier_id)[0];
        const categories = fp.categories.filter(c => c.tier_id === tier.id);
        const topics = fp.topics.filter(t => t.category_id === categoryId);
        const tasks = fp.tasks.filter(t => topics.map(t => t.id).includes(t.topic_id));

        let state = {
            tierId: tier.id,
            categoryId: categoryId,
            slide: 0,
            tier: tier,
            category: category,
            categories: categories,
            topics: topics,
            tasks: tasks,
        };
        if (unsetTier) {
            state.tierId = 0;
            state.tier = null;
        }

        this.setState(state);

        window.scrollTo(0,0);
    }

    setSlide = (slide) => {
        this.setState({ slide: slide });
    }

    expandCollapse = (id, type) => {
        let newVal = this.state[type].slice();

        if (newVal.includes(id)) {
            newVal.splice(newVal.indexOf(id), 1)
        } else {
            newVal.push(id);
        }

        let state = {};
        state[type] = newVal;
        this.setState(state);
    }

    toggleViewAll = (topicId) => {
        const viewAllOpen = (this.state.viewAllOpen) ? 0 : topicId;
        this.setState({ viewAllOpen: viewAllOpen });
    }

    toggleTask = (task) => {
        task.status = (task.status) ? 0 : 1;
        this.props.toggleTask(task, this.props.account.user);
        this.state.tasks.filter(t => t.id === task.id || t.parent_task_id === task.id || (t.id === task.parent_task_id && !task.status)).forEach(t => {
            t.status = task.status;
        });
        this.setState({ tasks: this.state.tasks });
    }

    addTopic = (topic) => {
        topic.visible = true;
        this.props.addTopic(topic, this.props.account.user);
        this.state.topics.filter(t => t.id === topic.id)[0].visible = topic.visible;
        this.state.tasks.filter(t => t.topic_id === topic.id).forEach(t => {
            t.visible = topic.visible;
        });
        this.setState({ topics: this.state.topics });
    }

    addRemoveTask = (task, addTopic) => {
        task.visible = (task.visible) ? 0 : 1;
        this.props.toggleTask(task, this.props.account.user);
        this.state.tasks.filter(t => t.id === task.id || t.parent_task_id === task.id).forEach(t => {
            t.visible = task.visible;
        });

        if (addTopic) {
            let topic = this.state.topics.filter(t => t.id === task.topic_id)[0]
            topic.visible = true;
            this.props.addTopic(topic, this.props.account.user);
            this.setState({ topics: this.state.topics });
        }

        const visibleTasks = this.state.tasks.filter(t => t.topic_id === task.topic_id && t.visible);
        if (visibleTasks.length === 0) {
            this.state.topics.filter(t => t.id === task.topic_id)[0].visible = false;
        }

        this.setState({
            tasks: this.state.tasks,
            topics: this.state.topics,
            editingTopic: false
        });
    }

    takeSurvey = () => {
        window.location.href = '/survey';
    }

    toggleSlideshow = () => {
        this.setState({ slideshowOpen: !this.state.slideshowOpen, slide: 0 });
    }

    changeSlide = (num) => {
        this.setState({ slide: num });
    }

    taskNav = (task) => {
        window.location.href = `/task?tid=${task.id}`;
    }

    previousTier = (e) => {
        const fp = this.props.financialPriorities;
        const sort = this.state.tier.sort_order;

        if (sort !== 1) {
            this.setTierId(e, fp.tiers.filter(t => t.sort_order === sort-1)[0].id);
        }
    }

    nextTier = (e) => {
        const fp = this.props.financialPriorities;
        const sort = this.state.tier.sort_order;

        if (sort !== fp.tiers.length) {
            this.setTierId(e, fp.tiers[sort].id);
        }
    }

    nextCategory = (e) => {
        const fp = this.props.financialPriorities;
        const sort = this.state.category.sort_order;

        if (sort !== this.state.categories.length) {
            this.setCategoryId(e, fp.categories[sort].id);
        } else {
            this.setCategoryId(e, this.state.categories[0].id);
        }
    }

    toggleEditTopic = (e, topic) => {
        e.preventDefault();

        if (!this.state.editingTopic) {
            let collapsed = this.state.collapsedTopics.slice();
            collapsed.splice(collapsed.indexOf(topic.id), 1);
            this.setState({ editingTopic: topic, collapsedTopics: collapsed });
        } else {
            this.setState({ editingTopic: false });
        }
    }

    render() {
        const self = this;

        const { loaded, dataLoaded, slide, slideshowOpen, collapsedTopics, takeSurveyOpen } = this.state;
        const { tierId, categoryId, tier, category, categories, topics, tasks, editingTopic, mobile } = this.state;
        const { account, financialPriorities, classes } = this.props;

        if (!loaded || (loaded && account.loaded && !dataLoaded)) {
            return (<Loading />);
        } else {
            // Calculate overall task progress for each tier //
            let overviewProgress = {};
            if (tierId === 0 && !categoryId) {
                financialPriorities.tiers.forEach(tier => {
                    const categoryIds = financialPriorities.categories.filter(c => c.tier_id === tier.id).map(c => c.id);
                    const topicIds = financialPriorities.topics.filter(t => categoryIds.includes(t.category_id) && t.visible).map(t => t.id);
                    const tasks = financialPriorities.tasks.filter(t => topicIds.includes(t.topic_id) && t.visible);
                    overviewProgress[tier.id] = (tasks.length) ? (tasks.filter(t => t.status).length / tasks.length) : 0;
                });
            }

            // Calculate how many tasks and categories are complete for the current tier //
            let tierProgress = {};
            if (tier && !category) {
                tierProgress.tasks = (tasks.length)
                    ? Math.round((tasks.filter(t => t.status && t.visible).length / tasks.filter(t => t.visible).length) * 100)
                    : 0;

                tierProgress.completedCategories = 0;
                categories.forEach(c => {
                    const allTopics = topics.filter(t => t.category_id === c.id && t.visible);
                    let topicCount = 0;

                    allTopics.forEach(topic => {
                        const allTasks = tasks.filter(task => task.topic_id === topic.id && task.visible);
                        const completedTasks = tasks.filter(task => task.topic_id === topic.id && task.status === 1 && task.visible);
                        if (allTasks.length > 0 && allTasks.length === completedTasks.length) {
                            topicCount += 1;
                        }
                    });

                    if (allTopics.length > 0 && topicCount === allTopics.length) {
                        tierProgress.completedCategories += 1;
                    }
                });

                tierProgress.categories = {};
                categories.forEach(c => {
                    const topicIds = topics.filter(t => t.visible && t.category_id === c.id).map(t => t.id);
                    const allTasks = tasks.filter(t => topicIds.includes(t.topic_id) && t.visible);
                    const completedTasks = tasks.filter(t => topicIds.includes(t.topic_id) && t.visible && t.status === 1);
                    tierProgress.categories[c.id] = (allTasks.length) ? Math.round((completedTasks.length / allTasks.length) * 100) : 0;
                });
            }

            // Calculate how many tasks and topics are complete for the current category //
            let categoryProgress = {}, categoryIcon = null;
            if (category) {
                categoryProgress.tasks = (tasks.filter(t => t.visible).length)
                    ? Math.round((tasks.filter(t => t.status && t.visible).length / tasks.filter(t => t.visible).length) * 100)
                    : 0;

                categoryProgress.visibleTopics = topics.filter(t => t.visible);

                categoryProgress.completedTopics = 0;
                categoryProgress.visibleTopics.forEach(topic => {
                    const allTasks = tasks.filter(task => task.topic_id === topic.id && task.visible);
                    const completedTasks = tasks.filter(task => task.topic_id === topic.id && task.status === 1 && task.visible);
                    if (allTasks.length > 0 && allTasks.length === completedTasks.length) {
                        categoryProgress.completedTopics += 1;
                    }
                });

                categoryIcon = category.name.replace(/[\s\-()]/g, '').replace(/[&]/g, '');
                if (category.name.includes('Financial Planner')) {
                    categoryIcon = 'FinancialPlanner';
                } else if (category.name.includes('Investing')) {
                    categoryIcon = 'OtherInvestments';
                } else if (category.name.includes('Trusts')) {
                    categoryIcon = 'WillandTrusts';
                }
            }

            const OtherTopicsIcon = (collapsedTopics.includes(0)) ? ExpandMoreIcon : ChevronRightIcon;

            return (
                <div className="wrapper">
                    <NavBarLoggedIn account={account} fp={financialPriorities} onFP={true} tierId={tierId} onNavClick={this.setTierId} />

                    <div className="main-bg" />

                    <div className="main financial-priorities">
                        {/* Introduction */}
                        {tierId === 0 && <>
                            <h1>Financial Priorities Action Plan</h1>

                            {slideshowOpen && <>
                                <div className="slideshow">
                                    <img src={SLIDE_IMAGES[slide]} className="slide-image" alt={slideData[slide].alt} />
                                    <div className="slideshow-content">
                                        <img src={CloseIcon} className="close-icon" alt="Close icon, X, grey" onClick={this.toggleSlideshow} />
                                        <h1 dangerouslySetInnerHTML={{__html: slideData[slide].header}} />
                                        <p>{slideData[slide].body}</p>
                                        <div className="arrows">
                                            <span className={cn('arrow back', slide === 0 && 'disabled')} onClick={() => this.changeSlide(Math.max(slide-1, 0))}>{'<'}</span>
                                            <span className={cn('arrow forward', slide === slideData.length-1 && 'disabled')} onClick={() => this.changeSlide(Math.min(slide+1, slideData.length-1))}>{'>'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="slideshow-dots">
                                    {slideData.map((s, i) => {
                                        return (<span key={i} className={cn('dot', slide === i && 'selected')} onClick={() => this.changeSlide(i)} />);
                                    })}
                                </div>
                            </>}

                            <section className="section-next-steps">
                                <h1>Next Steps</h1>
                                <div className={cn('next-steps')}>
                                    {financialPriorities.tasks.filter(t => !t.status && t.visible).map((task, i) => {
                                        if (i < 3) {
                                            return (
                                                <div className="next-step" key={i} onClick={() => this.taskNav(task)}>
                                                    <div className="faux-checkbox"></div>
                                                    <div className="task">{task.name}</div>
                                                    <ArrowIcon stroke={STROKES.white} />
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}

                                    {financialPriorities.tasks.length > 0 && financialPriorities.tasks.filter(t => !t.status && t.visible).length === 0 &&
                                        <div className="next-step no-next-steps" onClick={(e) => this.tierNav(e, {id: 0})}>
                                            <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                            <div className="task">Congratulations! You've completed the Financial Priorities action plan!</div>
                                        </div>
                                    }

                                    {financialPriorities.tasks.length === 0 &&
                                        <div className="next-step" onClick={this.showSurvey}>
                                            <div className="faux-checkbox"></div>
                                            <div className="task">Take the Financial Priorities Action Plan survey.</div>
                                            <ArrowIcon stroke={STROKES.white} />
                                        </div>
                                    }
                                </div>
                            </section>

                            <section className="quote-and-slideshow">
                                {slideshowOpen && <>
                                    <div className="quote-or-collapsed">
                                        <p>Financial progress is achieved through small steps in the right direction over time. Perfection is not required.</p>
                                    </div>
                                    <div className="quote-background" />
                                </>}

                                {!slideshowOpen && <>
                                    <div className="quote-or-collapsed">
                                        <h2>Overview:<br /><br />Your Financial Priorities Action Plan</h2>
                                        <PlusIcon stroke={STROKES.white} onClick={this.toggleSlideshow} />
                                    </div>
                                    <div className="quote-background" />
                                </>}
                            </section>

                            {!slideshowOpen &&
                                <div className="quote-collapsed-mobile">
                                    <div className="quote-or-collapsed mobile">
                                        <h2>Overview:<br /><br />Your Financial Priorities Action Plan</h2>
                                        <PlusIcon stroke={STROKES.white} onClick={this.toggleSlideshow} />
                                        <div className="quote-background mobile" />
                                    </div>
                                </div>
                            }

                            <section className="action-plan">
                                <h1>Action Plan</h1>
                                <a href="/plan"><h2 className="sub">Financial Priorities</h2></a>
                                <div className="tiers">
                                    {financialPriorities.tiers.map(tier => {
                                        const percentage = overviewProgress[tier.id];
                                        return (
                                            <div className="tier" key={tier.id} onClick={(e) => this.setTierId(e, tier.id)}>
                                                <div className="metadata">
                                                    <img src={tierIcons[tier.name]} alt={`Tier icon for the ${tier.name} tier`} />
                                                    <h2>{tier.name}</h2>
                                                </div>
                                                <div className="pie">
                                                    <PieChart width={(mobile) ? 70 : 105} height={(mobile) ? 70 : 105} >
                                                         <Pie
                                                            dataKey="value"
                                                            innerRadius={'90%'}
                                                            outerRadius={'100%'}
                                                            fill="#E6E6E6"
                                                            data={[{ value: 100 }]} />
                                                        <Pie
                                                            dataKey="value"
                                                            innerRadius={'85%'}
                                                            outerRadius={'105%'}
                                                            cornerRadius={40}
                                                            startAngle={90}
                                                            endAngle={((360 * percentage) + 90)}
                                                            fill="#40B7B0"
                                                            data={[{ value: Math.round(percentage * 100) }]} />
                                                    </PieChart>
                                                    <label>{`${Math.round(percentage*100)}%`}</label>
                                                    <ArrowIcon stroke={STROKES.grey} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </section>

                            <section className="quote-and-slideshow mobile">
                                <div className="quote-or-collapsed">
                                    <p>Financial progress is achieved through small steps in the right direction over time. Perfection is not required.</p>
                                </div>
                                <div className="quote-background" />
                            </section>
                        </>}

                        {/* Tier/Category Header */}
                        {(tierId > 0 || categoryId !== 0) && <>
                            <h1 className={cn(tierId !== 0 && categoryId !== 0 && 'cat-header')}>
                                <span onClick={(e) => this.setTierId(e, tierId)}>{tierId !== 0 && categoryId !== 0 && mobile && '< '}{tier.name}</span>
                                <div className="arrows">
                                    <span className={cn('arrow back', tierId === financialPriorities.tiers[0].id && 'disabled')}
                                        onClick={this.previousTier}>{'<'}</span>
                                    <span className={cn('arrow forward', tierId === financialPriorities.tiers[financialPriorities.tiers.length-1].id && 'disabled')}
                                        onClick={this.nextTier}>{'>'}</span>
                                </div>
                            </h1>

                            <h2 className="sub cat-nav">
                                {categories.map((c, i, arr) => {
                                    return (
                                        <span key={i}>
                                            <a href="noop" className={cn(category?.id === c.id && 'selected')} onClick={(e) => this.setCategoryId(e, c.id)}>{c.name}</a>
                                            {i !== arr.length-1 && <span> / </span>}
                                        </span>
                                    );
                                })}
                            </h2>
                        </>}

                        {/* Tier Page */}
                        {tierId > 0 && categoryId === 0 && <>
                            <div className="tier-header">
                                <div className="header-content">
                                    <img src={tierIcons[tier.name]} className="tier-icon" alt={`Tier icon for the ${tier.name} tier`} />
                                    <h1>{tier.name}</h1>
                                    <p>{tier.description}</p>
                                    <div className="progress">
                                        <div className="progress-bar">
                                            <div className="pace" style={{ width: tierProgress.tasks + '%' }} />
                                        </div>
                                        <div className="progress-details">
                                            <label><span>{tierProgress.tasks + '%'}</span> of Tasks Complete</label>
                                        </div>
                                    </div>
                                </div>

                                <img src={TIER_IMAGES[tier.sort_order-1]} className="tier-image" alt={tier.description} />

                                <img src={NextIcon} className="tier-nav previous" alt="Previous tier icon, left arrow in grey circle, translucent" onClick={this.previousTier} />
                                <img src={NextIcon} className="tier-nav next" alt="Next tier icon, right arrow in grey circle, translucent" onClick={this.nextTier} />

                                <div className="tier-nav-mobile">
                                    <span className={cn('arrow back', tierId === 1 && 'disabled')} onClick={this.previousTier}>{'<'}</span>
                                    <span className={cn('arrow forward', tierId === 5 && 'disabled')} onClick={this.nextTier}>{'>'}</span>
                                </div>
                            </div>

                            <h1>{`${tier.name} Tasks`}</h1>
                            <div className="categories">
                                {categories.map((c, i) => {
                                    const percentComplete = tierProgress.categories[c.id];

                                    return (
                                        <div className="category" key={i}>
                                            <h2>{c.name}</h2>
                                            <p>{c.description}</p>
                                            <div className="progress">
                                                <div className="progress-bar">
                                                    <div className="pace" style={{ width: percentComplete + '%' }} />
                                                </div>
                                                <div className="progress-details">
                                                    <label><span>{percentComplete + '%'}</span> of Tasks Complete</label>
                                                </div>
                                            </div>
                                            <ArrowIcon stroke={STROKES.grey} onClick={(e) => this.setCategoryId(e, c.id)} />
                                        </div>
                                    );
                                })}
                            </div>
                        </>}

                        {/* Category Page */}
                        {tierId > 0 && categoryId !== 0 && <>
                            <div className="category-wrapper">
                                <div className="category-detail">
                                    <div className="header">
                                        <img src={categoryIcons[categoryIcon]}
                                            className="category-icon" alt={`Category icon for ${category.name} category`} />
                                        <h1>{category.name}</h1>
                                        <div className="category-nav-mobile">
                                            <span className="arrow forward" onClick={this.nextCategory}>{'>'}</span>
                                        </div>
                                        {categoryProgress.visibleTopics.length > 0 &&
                                            <div className="progress">
                                                <div className="progress-bar">
                                                    <div className="pace" style={{ width: categoryProgress.tasks + '%' }} />
                                                </div>
                                                <div className="progress-details">
                                                    <label><span>{categoryProgress.tasks + '%'}</span> of Tasks Complete</label>
                                                    <label><span>{`${categoryProgress.completedTopics}/${categoryProgress.visibleTopics.length}`}</span> Topics Complete</label>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {topics.length === 0 && <p className="no-topics">There are no topics to complete, you're all set!</p>}

                                    {categoryProgress.visibleTopics.map((topic, i) => {
                                        const visibleTasks = tasks.filter(task => task.topic_id === topic.id && task.visible && !task.parent_task_id);
                                        const allTasks = tasks.filter(task => task.topic_id === topic.id && !task.parent_task_id);
                                        const tasksToMap = (editingTopic.id === topic.id) ? allTasks : visibleTasks;
                                        const completedTasks = tasks.filter(task => task.topic_id === topic.id && task.visible && task.status === 1 && !task.parent_task_id);
                                        const Icon = (collapsedTopics.includes(topic.id)) ? ChevronRightIcon : ExpandMoreIcon;
                                        const editLinkText = (editingTopic.id === topic.id) ? 'Close' : 'Edit';

                                        return (
                                            <div className="topic" key={i}>
                                                <div className="topic-header">
                                                    <h2>{topic.name}</h2>
                                                    <Icon onClick={() => this.expandCollapse(topic.id, 'collapsedTopics')} />
                                                    <span className="tasks-progress">{completedTasks.length}/{visibleTasks.length}</span>
                                                    <a href="noop" className="edit" onClick={(e) => this.toggleEditTopic(e, topic)}>{editLinkText}</a>
                                                </div>

                                                {visibleTasks.length === 0 && <p>There are no tasks to complete, you're all set!</p>}

                                                <div className={cn('tasks', collapsedTopics.includes(topic.id) && 'collapsed')}>
                                                    {editingTopic.id === topic.id &&
                                                        <div className="description">
                                                            <p>To remove tasks from your plan click the 'x' icon.</p>
                                                            <p>To add tasks to your plan, click the '+' icon.</p>
                                                        </div>
                                                    }

                                                    {tasksToMap.map((task, i) => {
                                                        let checkbox = (<div className="faux-checkbox" onClick={() => this.toggleTask(task)}></div>);
                                                        if (task.status === 1) {
                                                            checkbox = (<Checkbox checked={true} onChange={() => this.toggleTask(task)} />);
                                                        }

                                                        let actionIcon = (<ArrowIcon stroke={STROKES.white} onClick={() => this.taskNav(task)} />);
                                                        if (editingTopic.id === topic.id) {
                                                            if (task.visible) {
                                                                actionIcon = (
                                                                    <img src={RemoveIcon} className="remove-icon" alt="Remove icon, X in circle, white" onClick={() => this.addRemoveTask(task)} />
                                                                );
                                                            } else {
                                                                actionIcon = (
                                                                    <PlusIcon stroke={STROKES.white} onClick={() => this.addRemoveTask(task)} />
                                                                );
                                                            }
                                                        }

                                                        return (
                                                            <div className={cn('task-line', task.status === 1 && 'completed')} key={i}>
                                                                {checkbox}
                                                                <div className="task">{task.name}</div>
                                                                {actionIcon}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}

                                    {topics.filter(t => !t.visible).length > 0 &&
                                        <div className="topic">
                                            <div className="topic-header">
                                                <h2>Additional topics</h2>
                                                <OtherTopicsIcon onClick={() => this.expandCollapse(0, 'collapsedTopics')} />
                                            </div>

                                            <div className="description">
                                                <p>The {category.name} category has additional topics not currently in your plan.
                                                You may add them if they are relevant to your current situation.</p>
                                            </div>

                                            <div className={cn('other-topics topic', !collapsedTopics.includes(0) && 'collapsed')}>
                                                {topics.filter(t => !t.visible).map((topic, i) => {
                                                    const Icon = (collapsedTopics.includes(topic.id)) ? ExpandMoreIcon : ChevronRightIcon;

                                                    return (
                                                        <div key={i}>
                                                            <div className="topic">
                                                                <div className="topic-header">
                                                                    <h2>{topic.name}</h2>
                                                                    <Icon onClick={() => this.expandCollapse(topic.id, 'collapsedTopics')} />
                                                                </div>
                                                            </div>
                                                            <div className={cn('tasks other-tasks', !collapsedTopics.includes(topic.id) && 'collapsed')}>
                                                                {tasks.filter(t => t.topic_id === topic.id && !t.parent_task_id).map((task, j) => {
                                                                    return (
                                                                        <div className="task-line" key={j}>
                                                                            <div className="faux-checkbox"></div>
                                                                            <div className="task">{task.name}</div>
                                                                            <PlusIcon stroke={STROKES.white} onClick={() => self.addRemoveTask(task, true)} />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="category-description">
                                    <div className="description">
                                        <p>{category.description}</p>
                                    </div>
                                    <div className="background" />
                                </div>
                            </div>
                        </>}
                    </div>

                    {takeSurveyOpen &&
                        <Dialog open={takeSurveyOpen} className={classes.Dialog}>
                            <h1>Welcome to MoneySwell!</h1>
                            <p>To view your Financial Priorities action plan, please take a quick survey first.</p>
                            <button className="primary" onClick={this.takeSurvey}>Take the survey</button>
                        </Dialog>
                    }

                    <Footer />
                </div>
            );
        }
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
    connect(mapStateToProps, mapDispatchToProps)
)(FinancialPriorities);
