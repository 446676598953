import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './IconButton.scss';

class IconButton extends Component {
    static propTypes = {
        type: PropTypes.string,
        content: PropTypes.node,
        onClick: PropTypes.func,
        tooltip: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
    };

    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };
    }

    closeTooltip = () => {
        this.setState({ tooltipOpen: false });
    }

    toggleTooltip = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen });
    }

    handleOnClick = (e) => {
        e.stopPropagation();
        this.toggleTooltip();
        this.props.onClick();
    }

    render() {
        const { type, content, onClick, tooltip, placement, ...rest } = this.props;
        const { tooltipOpen } = this.state;

        if (tooltip) {
            return (
                <Tooltip title={tooltip} arrow open={tooltipOpen} placement={placement || 'right'}>
                    <span className={'icon-button ' + type} onClick={this.handleOnClick} onMouseOver={this.toggleTooltip} onMouseOut={this.closeTooltip} {...rest}>
                        {content}
                    </span>
                </Tooltip>
            );
        } else {
            return (
                <span className={'icon-button ' + type} onClick={onClick} {...rest}>
                    {content}
                </span>
            );
        }
    }
}

export default IconButton;
