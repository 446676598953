import moment from 'moment';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import numeral from 'numeral';
import { ASSETS, LIABILITIES, BREAKDOWNS, NET_WORTH, DAY_FORMAT, WEEKDAYS } from 'constants/assetsLiabilities';
import { NUM_FORMAT } from 'constants/config';

// Generate a legend object with each account under its parent account type //
const generateLegend = (historical, formattedData) => {
    historical && Object.keys(historical).forEach(accountId => {
        const h = historical[accountId][0];

        if (typeof(h) === 'undefined') {
            return;
        }

        let category = (h.category === 'assets') ? ASSETS : LIABILITIES;
        let accountType = Object.assign({}, find(category, {value: h.type}));

        accountType.name = h.name;
        accountType.category = h.category;
        accountType.selected = true;

        if (!formattedData.legend[accountType.parent]) {
            formattedData.legend[accountType.parent] = [accountType];
        } else if (!find(formattedData.legend[accountType.parent], {name: h.name})) {
            formattedData.legend[accountType.parent].push(accountType);
        }

        if (!formattedData.accountsByType[accountType.category][accountType.label]) {
            formattedData.accountsByType[accountType.category][accountType.label] = [accountType];
        } else if (!find(formattedData.accountsByType[accountType.category][accountType.label], {name: h.name})) {
            formattedData.accountsByType[accountType.category][accountType.label].push(accountType);
        }

        if (!find(formattedData.accounts, {name: h.name})) {
            formattedData.accounts.push(accountType);
        }
    });
};

// Fill out each account's records so we have one record per day for each c //
const padDailyData = (historical, startDate, endDate, exclude) => {
    historical && Object.keys(historical).forEach(accountId => {
        if (historical[accountId][0] && exclude.includes(historical[accountId][0].name)) {
            return;
        }

        const records = historical[accountId].slice();

        let paddedRecords = [], idx = 0, prev = null;
        let current = moment.utc(startDate), end = moment.utc(endDate);

        while (current <= end) {
            if (idx < records.length) {
                let added = false, record = moment.utc(records[idx].date).format(DAY_FORMAT);
                let currentFormatted = current.format(DAY_FORMAT);

                // Increment if current record before start, otherwise we would always use the first record's values. //
                // Edit 2024.08.07: If there are dupes and the current is a dupe of the prev, skip it //
                if ((record < currentFormatted && records[idx+1]) || (prev && prev.date.split('T')[0] === record)) {
                    idx++;
                }

                // If the current record matches the current day, use it //
                if (record === currentFormatted) {
                    prev = records[idx++];
                    paddedRecords.push(prev);
                    added = true;
                }

                // Otherwise, use the previous day's data (and don't increment the index) //
                if (!added && (prev || record <= currentFormatted)) {
                    let newRecord = Object.assign({}, prev || records[idx]);
                    newRecord.date = currentFormatted;
                    paddedRecords.push(newRecord);
                }
            }

            current.add(1, 'days');
        }

        historical[accountId] = paddedRecords;
    });
};

const getLatestForAggregation = (historical, breakdownKey) => {
    historical && Object.keys(historical).forEach(accountId => {
        const records = historical[accountId].slice();

        let breakdownRecords = [],
            idx = 0,
            breakdown = BREAKDOWNS[breakdownKey];

        records.forEach((r, i) => {
            let current = moment.utc(r.date),
                previous = (breakdownRecords[idx]) ? moment.utc(breakdownRecords[idx].date) : null;

            let sameAsPrevious = false;
            if (previous) {
                switch (breakdown.breakdown) {
                    case 'yearly':
                        sameAsPrevious = (previous.year() === current.year());
                        break;
                    case 'monthly':
                        sameAsPrevious = (previous.month() === current.month());
                        break;
                    case 'weekly':
                        sameAsPrevious = (previous.week() === current.week());
                        break;
                    default:
                        sameAsPrevious = false;
                        break;
                }
            }

            if (i === 0) {
                breakdownRecords.push(r);
            } else if (sameAsPrevious) {
                breakdownRecords[idx] = r;
            } else if (!sameAsPrevious) {
                breakdownRecords.push(r);
                idx++;
            }
        });

        historical[accountId] = breakdownRecords;
    });
};

const generateChartData = (historical, formattedData, breakdownKey, exclude) => {
    historical && Object.keys(historical).forEach(accountId => {
        const records = historical[accountId].slice();
        let idx = 0;

        records.forEach((r, i) => {
            let entryIndex = findIndex(formattedData.data, {date: moment.utc(r.date).format(BREAKDOWNS[breakdownKey].format)});
            let entry = (entryIndex !== -1)
                ? formattedData.data[entryIndex]
                : { date: moment.utc(r.date).format(BREAKDOWNS[breakdownKey].format), 'Net Worth': 0};
            let category = (r.category === 'assets') ? ASSETS : LIABILITIES;
            let parent = find(category, {value: r.type}).parent || 'Other';

            // If the first value for this category, add to entry //
            // (only if not excluded) //
            if (!exclude.includes(r.name) && !exclude.includes(parent)) {
                if (!entry[parent]) {
                    entry[parent] = Number(r.value);
                } else {
                    // Otherwise add it to the running total //
                    entry[parent] += Number(r.value);
                }

                // If it's an asset, increment the net worth for this date //
                if (category === ASSETS) {
                    entry[NET_WORTH] += Number(r.value);
                } else {
                    // Otherwise, subtract liabilities from net worth //
                    entry[NET_WORTH] -= Number(r.value);
                }
            }

            formattedData.data[(entryIndex !== -1 ? entryIndex : idx++)] = entry;
        });
    });
};

export const formatData = (historical, startDate, endDate, breakdownKey, exclude) => {
    let formattedData = {
        legend: {},
        accountsByType: {assets: {}, liabilities: {}},
        accounts: [],
        data: [],
    };

    // Add all accounts to legend object (and accountsByType object) //
    generateLegend(historical, formattedData);

    // Fill out each account's records so we have one record per day for each account //
    padDailyData(historical, startDate, endDate, exclude);

    // If using a different breakdown, get the latest data for each aggregation unit //
    if (breakdownKey !== 'daily') {
        getLatestForAggregation(historical, breakdownKey);
    }

    // Create a single entry for each aggregation unit (day, month, year) for all accounts //
    generateChartData(historical, formattedData, breakdownKey, exclude);

    return formattedData;
};

export const formatTooltipValues = (value) => {
    return numeral(value).format(NUM_FORMAT);
};

export const accountNeedsUpdateWithin2Days = (account) => {
    return accountPastDueUpdate(account, moment().add(2, 'days'), true);
};

export const accountPastDueUpdate = (account, today = moment(), is2DaysCheck) => {
    let pastDueUpdate = false,
        lastMod = moment(account.modified),
        startDate = moment(account.reminderStartDate),
        realMonthDay = account.monthDay,
        weekdays = account.weekdays?.map(d => find(WEEKDAYS, {full: d})?.id || null);

    // Set real last day of month if value is 'last' //
    if (realMonthDay && realMonthDay === 'last') {
        realMonthDay = moment().endOf('month').date();
    }

    if (account.customFreq === 'months' && account.reminderType === 'On Day') {
        // If today is on or after update day, and last modified is before update day, and it's the right number of months, we're past due //
        const onOrAfterUpdateDay = today.date() >= realMonthDay;
        const lastModDate = lastMod.date();
        const diffMonths = today.diff(startDate, 'months');

        pastDueUpdate = (onOrAfterUpdateDay && (lastModDate < realMonthDay) && (diffMonths % account.customNum === 0));
    } else if (account.customFreq === 'weeks' && account.reminderType === 'On Day') {
        // Same as the above, only day(s) of week instead of date of month //
        let onUpdateDay = weekdays?.includes(today.day());
        if (is2DaysCheck) {
            for (let i = 0; i < weekdays?.length; i++) {
                const diff = weekdays[i] - moment().day();
                if (diff <= 2 && diff >= 0) {
                    onUpdateDay = true;
                    break;
                }
            }
        }
        const diffWeeks = today.diff(startDate, 'weeks');

        pastDueUpdate = (onUpdateDay && (lastMod.endOf('day') < today.startOf('day')) && (diffWeeks % account.customNum === 0));
    } else if (account.customFreq && account.reminderType === 'From Last Update') {
        // Only needs updating every customNum customFreqs from the last update (e.g. every 3 weeks, every 1 month) //
        const customDiff = today.diff(lastMod, account.customFreq);

        pastDueUpdate = ((customDiff >= account.customNum) && (customDiff % account.customNum === 0));
    }

    return pastDueUpdate;
};
