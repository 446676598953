export const categories = [
    { label: 'Clothing', options: [
        { value: 'Adult clothing', label: 'Adult clothing' },
        { value: "Children's clothing", label: "Children's clothing" },
        { value: 'Clothing', label: 'Clothing' },
    ]},
    { label: 'Debt Payments', options: [
        { value: 'Personal loans', label: 'Personal loans' },
        { value: 'Student loans', label: 'Student loans' },
        { value: 'Credit cards', label: 'Credit cards' },
        { value: 'Debt Payments', label: 'Debt Payments' },
    ]},
    { label: 'Education', options: [
        { value: 'Tuition and Fees', label: 'Tuition and Fees' },
        { value: 'School supplies', label: 'School supplies' },
        { value: 'Books', label: 'Books' },
        { value: 'Education', label: 'Education' },
    ]},
    { label: 'Entertainment', options: [
        { value: 'Alcohol and/or bars', label: 'Alcohol and/or bars' },
        { value: 'Games', label: 'Games' },
        { value: 'Movies', label: 'Movies' },
        { value: 'Concerts', label: 'Concerts' },
        { value: 'Vacations', label: 'Vacations' },
        { value: 'Subscriptions (Netflix, Amazon, Spotify, etc.)', label: 'Subscriptions (Netflix, Amazon, Spotify, etc.)' },
        { value: 'Entertainment', label: 'Entertainment' },
    ]},
    { label: 'Food', options: [
        { value: 'Groceries', label: 'Groceries' },
        { value: 'Restaurants', label: 'Restaurants' },
        { value: 'Pet food', label: 'Pet food' },
        { value: 'Food', label: 'Food' },
    ]},
    { label: 'Gifts/Donations', options: [
        { value: 'Birthday', label: 'Birthday' },
        { value: 'Anniversary', label: 'Anniversary' },
        { value: 'Wedding', label: 'Wedding' },
        { value: 'Special occasion', label: 'Special occasion' },
        { value: 'Charities', label: 'Charities' },
        { value: 'Gifts/Donations', label: 'Gifts/Donations' },
    ]},
    { label: 'Household Items/Supplies', options: [
        { value: 'Toiletries', label: 'Toiletries' },
        { value: 'Laundry detergent', label: 'Laundry detergent' },
        { value: 'Dishwasher detergent', label: 'Dishwasher detergent' },
        { value: 'Cleaning supplies', label: 'Cleaning supplies' },
        { value: 'Tools', label: 'Tools' },
        { value: 'Household Items/Supplies', label: 'Household Items/Supplies' },
    ]},
    { label: 'Housing', options: [
        { value: 'Mortgage or rent', label: 'Mortgage or rent' },
        { value: 'Property taxes', label: 'Property taxes' },
        { value: 'Household repairs', label: 'Household repairs' },
        { value: 'HOA fees', label: 'HOA fees' },
        { value: 'Housing', label: 'Housing' },
    ]},
    { label: 'Insurance', options: [
        { value: 'Health insurance', label: 'Health insurance' },
        { value: "Homeowner’s or renter’s insurance", label: "Homeowner’s or renter’s insurance" },
        { value: 'Home warranty or protection plan', label: 'Home warranty or protection plan' },
        { value: 'Auto insurance', label: 'Auto insurance' },
        { value: 'Life insurance', label: 'Life insurance' },
        { value: 'Disability insurance', label: 'Disability insurance' },
        { value: 'Insurance', label: 'Insurance' },
    ]},
    { label: 'Medical/Healthcare', options: [
        { value: 'Primary care', label: 'Primary care' },
        { value: 'Dental care', label: 'Dental care' },
        { value: 'Specialty care (dermatologists, orthodontics, optometrists, etc.)', label: 'Specialty care (dermatologists, orthodontics, optometrists, etc.)' },
        { value: 'Urgent care', label: 'Urgent care' },
        { value: 'Medications', label: 'Medications' },
        { value: 'Medical devices', label: 'Medical devices' },
        { value: 'Medical/Healthcare', label: 'Medical/Healthcare' },
    ]},
    { label: 'Personal', options: [
        { value: 'Gym memberships', label: 'Gym memberships' },
        { value: 'Haircuts', label: 'Haircuts' },
        { value: 'Salon services', label: 'Salon services' },
        { value: 'Cosmetics (like makeup or services like laser hair removal)', label: 'Cosmetics (like makeup or services like laser hair removal)' },
        { value: 'Babysitter', label: 'Babysitter' },
        { value: 'Subscriptions', label: 'Subscriptions' },
        { value: 'Personal', label: 'Personal' },
    ]},
    { label: 'Retirement', options: [
        { value: 'Financial planning', label: 'Financial planning' },
        { value: 'Investing', label: 'Investing' },
        { value: 'Retirement', label: 'Retirement' },
    ]},
    { label: 'Transportation', options: [
        { value: 'Car payment', label: 'Car payment' },
        { value: 'Car warranty', label: 'Car warranty' },
        { value: 'Gas', label: 'Gas' },
        { value: 'Tires', label: 'Tires' },
        { value: 'Maintenance and oil changes', label: 'Maintenance and oil changes' },
        { value: 'Parking fees', label: 'Parking fees' },
        { value: 'Repairs', label: 'Repairs' },
        { value: 'Registration and DMV Fees', label: 'Registration and DMV Fees' },
        { value: 'Transportation', label: 'Transportation' },
    ]},
    { label: 'Utilities', options: [
        { value: 'Electricity', label: 'Electricity' },
        { value: 'Water', label: 'Water' },
        { value: 'Garbage', label: 'Garbage' },
        { value: 'Phones', label: 'Phones' },
        { value: 'Cable', label: 'Cable' },
        { value: 'Internet', label: 'Internet' },
        { value: 'Utilities', label: 'Utilities' },
    ]},
];
