import React, { Component } from 'react';

class MinusIcon extends Component {
    render() {
        const { stroke, onClick } = this.props;

        return (
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="minus-icon" onClick={onClick}>
                <circle cx="12.5" cy="13.2256" r="11.75" stroke={stroke} strokeWidth="1.5"/>
                <path d="M6.5 13.2256L18.5 13.2256" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }
}

MinusIcon.defaultProps = {
    stroke: '#CFCFCF',
    onClick: () => { return null; }
};

export default MinusIcon;
