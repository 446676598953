import {
    FINANCIAL_FACT_LOAD_ERROR,
    FINANCIAL_FACT_LOAD_SUCCESS,
    SEND_ERROR_EMAIL_ERROR,
    SEND_ERROR_EMAIL_SUCCESS,
} from '../constants/actionTypes';
import miscService from '../services/miscService';

export const loadFinancialFact = (user) => dispatch => (
    miscService.loadFinancialFact(user)
        .then(res => {
            dispatch({
                type: FINANCIAL_FACT_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: FINANCIAL_FACT_LOAD_ERROR,
                payload: e,
            });
        })
);

export const emailError = (user, error) => dispatch => (
    miscService.emailError(user, error)
        .then(res => {
            dispatch({
                type: SEND_ERROR_EMAIL_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SEND_ERROR_EMAIL_ERROR,
                payload: e,
            });
        })
);
